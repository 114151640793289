import { useEffect } from 'react';
import { BlockerFunction, unstable_usePrompt as usePrompt } from 'react-router-dom';
import i18next from 'i18next';

export interface UnsavedChangesPromptProps {
  isDirty: boolean;
  unsavedPrefix?: string;
}

export const UnsavedChangesPrompt = ({ isDirty, unsavedPrefix }: UnsavedChangesPromptProps) => {
  usePrompt({ message: i18next.t('generic-unsaved-changes'), when: ({ currentLocation, nextLocation }) => {
    if (!isDirty) return false

    if (unsavedPrefix) {
      if (currentLocation.pathname.startsWith(unsavedPrefix) && nextLocation.pathname.startsWith(unsavedPrefix)) return false
    }
    return true
  }})
  useEffect(() => {
    // Detecting browser closing
    window.onbeforeunload = isDirty ? () => i18next.t('generic-unsaved-changes') : null;

    return () => {
      window.removeEventListener('beforeunload', () => {});
    };
  }, [isDirty]);
  return null;
};
