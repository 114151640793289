import { gql } from "__generated__";

export const PUBLIC_CLIENT_LIST_SHORT_FRAGMENT = gql(`
  fragment PublicClientListShort on HL_PublicClientOutput {
    id
    firstname
    lastname
    email
    phone
    publicCompany {
      id
      name
      isPremium
    }
  }
`);

export const PUBLIC_CLIENT_LIST_FRAGMENT = gql(`
  fragment PublicClientListOutput on HL_PublicClientOutput {
    id
    firstname
    lastname
    email
    phone
    publicCompanyId
    publicCompany {
      id
      name
      isPremium
    }
  }
`);

export const PUBLIC_COMPANY_LIST_SHORT_FRAGMENT = gql(`
  fragment PublicCompanyListShort on HL_PublicCompanyOutput {
    id
    name
    address
    zip
    city
    isPremium
    clientsCount
    showPricesInNet
  }
`);

export const PUBLIC_CLIENT_LIST_QUERY = gql(`
  query ListPublicClients($where: PublicClientWhereInput, $skip: Int, $take: Int) {
    listPublicClients(where: $where, skip: $skip, take: $take) {
      ...PublicClientListShort
    }
  }
`);

export const PUBLIC_COMPANY_LIST_QUERY = gql(`
  query ListPublicCompanies($where: PublicCompanyWhereInput, $skip: Int, $take: Int) {
    listPublicCompanies(where: $where, skip: $skip, take: $take) {
      ...PublicCompanyListShort
    }
  }
`);

export const VIEW_PUBLIC_CLIENT_QUERY = gql(`
  query ViewPublicClient($id: Int!) {
    viewPublicClient(id: $id) {
      ...PublicClientListOutput
    }
  }
`);

export const VIEW_PUBLIC_COMPANY_QUERY = gql(`
  query ViewPublicCompany($id: Int!) {
    viewPublicCompany(id: $id) {
      ...PublicCompanyListShort
    }
  }
`);

export const UPDATE_PUBLIC_CLIENT_MUTATION = gql(`
  mutation UpdatePublicClient($id: Int!, $data: PublicClientInput!) {
    updatePublicClient(id: $id, data: $data) {
      ...PublicClientListOutput
    }
  }
`);

export const UPDATE_PUBLIC_COMPANY_MUTATION = gql(`
  mutation UpdatePublicCompany($id: Int!, $data: PublicCompanyInput!) {
    updatePublicCompany(id: $id, data: $data) {
      ...PublicCompanyListShort
    }
  }
`);