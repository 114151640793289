import { gql } from '__generated__/gql';
import { ApolloCache } from '@apollo/client';
import { EVICT_OFFER_QUERIES, REFETCH_OFFER_QUERIES } from 'pages/offers/gql';

export const INVOICE_EVENT_DETAILS_FRAGMENT = gql(`
  fragment InvoiceEventDetailsOutput on AMEventOutput {
    id
    serviceDirectPay
    roomDirectPay
    extraDirectPay
    parkingDirectPay
    coachInvoicingRule
    noshowInvoicingRule
    attendances {
      id
      serviceDirectPay
      roomDirectPay
      extraDirectPay
      parkingDirectPay
      invoicingRule
      profile {
        id
        firstname
        lastname
      }
      days {
        id
        day
        attending
        forenoon
        morning
        lunch
        afternoon
        dinner
        room
        morningShow
        forenoonShow
        lunchShow
        afternoonShow
        dinnerShow
        roomShow
        invoicingRule
        serviceDirectPay
        roomDirectPay
        extraDirectPay
        parkingDirectPay
      }      
    }
  }
`);

export const OFFERVERSION_INVOICE_VIEW_FRAGMENT = gql(`
  fragment WLOfferVersionInvoiceView on WLOfferVersionListOutput {
    invoice { costCentre accountingArea }
    sender {
      businessName
      businessAddress1
      businessAddress2
      businessAddress3
      businessAddress4
      businessZip
      businessCity
      businessCountry
      businessEmail
      businessPhone
      contactName
      comment
    }
    receiver {
      businessName
      businessAddress1
      businessAddress2
      businessAddress3
      businessAddress4
      businessZip
      businessCity
      businessCountry
      businessEmail
      businessPhone
      contactName
      comment
    }
  }
`)

export const INVOICE_VIEW_QUERY = gql(`
  query ViewInvoice($id: Int!) {
    viewWlOffer(id: $id) {
      ...WLOfferListView
    }
    viewWlOfferInvoiceVersion(offerId: $id) {
      ...WLOfferVersionListView
      ...WLOfferVersionInvoiceView
    }
    viewWlOfferGeneratedInvoices(offerId: $id) {
      id title type status
    }
    viewWlOfferInvoiceEvent(offerId: $id) {
      ...InvoiceEventDetailsOutput
    }
    invoiceQuickPriceDehydrate(offerId: $id)
    invoiceProductTextDehydrate(offerId: $id)
  }
`);

export const LIST_INVOICE_COSTCENTRES_QUERY = gql(`
  query ListInvoiceCostCentres($offerId: Int!) {
    listInvoiceCostCentres(offerId: $offerId) {
      id
      name
      costCentre
      billingComment
    }
  }
`);

export const INVOICE_VIEW_MEDIA_QUERY = gql(`
  query ViewInvoiceMedia($mediaId: Int!) {
    readWlOfferInvoiceMediaBase64(mediaId: $mediaId)
  }
`);

export const INVOICE_APPROVE_MUTATION = gql(`
  mutation ApproveInvoice($offerVersionId: Int!, $attendances: [WLInvoiceAttendanceInput!], $replaceLineItems: [WLOfferVersionContentBlockLineItemInput!], $extraLineItems: [WLOfferVersionContentBlockLineItemInput!], $deleteLineItemIds: [Int!], $sender: WLInvoiceSenderInput, $manualCancellationWaiveNet: Float, $approveAsHotel: Boolean!, $approveAsSEM: Boolean!) {
    approveInvoice(offerVersionId: $offerVersionId, attendances: $attendances, replaceLineItems: $replaceLineItems, extraLineItems: $extraLineItems, deleteLineItemIds: $deleteLineItemIds, sender: $sender, manualCancellationWaiveNet: $manualCancellationWaiveNet, approveAsHotel: $approveAsHotel, approveAsSEM: $approveAsSEM) {
      ...WLOfferVersionListView
    }
  }
`);

export const INVOICE_REJECT_SEM_MUTATION = gql(`
  mutation RejectInvoiceSEM($offerVersionId: Int!) {
    rejectInvoiceSEM(offerVersionId: $offerVersionId)
  }
`);
export const INVOICE_GENERATE_MUTATION = gql(`
  mutation GenerateClientInvoices($offerVersionId: Int!, $costCentres: [String!]!) {
    generateClientInvoices(offerVersionId: $offerVersionId, costCentres: $costCentres) {
      id
      title
      type
      status
    }
  }
`);

export const EVICT_INVOICE_QUERIES = (cache: ApolloCache<any>) => {
  EVICT_OFFER_QUERIES(cache)
};

export const REFETCH_INVOICE_QUERIES = (offerId?: number | undefined, offerVersionId?: number | undefined) => [
  ...(offerId
    ? [
        {
          query: INVOICE_VIEW_QUERY,
          variables: { id: offerId },
        },
      ]
    : []),
  ...REFETCH_OFFER_QUERIES(offerId, offerVersionId)
];
