import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import i18next from 'i18next';

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Button, Grid, Tab, Tabs } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { FormInputText } from 'components/form/FormInputText';
import SimpleAccordion from 'components/SimpleAccordion';
import {
  OfferTemplateInput,
  OfferTemplateContentBlock,
  OfferTemplateFormField,
} from '../../semshared/offer/offer';
import { FormInputDropdown } from 'components/form/FormInputDropdown';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { FormInputRichText } from 'components/form/FormInputRichTextEditor';

interface IEditorAccordionFormFieldDummyProps {
  cssStyles: string;
  contentBlock: OfferTemplateContentBlock;
  contentBlockIndex: number;
  formFieldIndex: number;
  control: any;
  disabled?: boolean;
}

interface IEditorAccordionLineItemComponentDummyProps {
  contentBlock: OfferTemplateContentBlock;
  contentBlockIndex: number;
  lineItemIndex: number;
  componentIndex: number;
  control: any;
  disabled?: boolean;
  onLineItemChanged: (index: number, lineItemIndex: number) => void;
  onLineItemPriceComponentChanged: (index: number, lineItemIndex: number, componentIndex: number) => void;
}


function EditorAccordionFormFieldDummy({
  cssStyles,
  contentBlock,
  contentBlockIndex,
  formFieldIndex,
  control,
  disabled,
}: IEditorAccordionFormFieldDummyProps) {
  const { watch, setValue, getValues } = useFormContext<OfferTemplateInput>();
  const type = watch(
    `contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.type`,
    _.get(contentBlock, `formFields[${formFieldIndex}].type`),
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormInputDropdown
          control={control}
          name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.type`}
          label={i18next.t('offerblock-formfield-type')}
          options={[
            {
              value: 'TEXT',
              label: i18next.t('offerblock-formfield-type-text'),
            },
            {
              value: 'TEXTAREA_PLAINTEXT',
              label: i18next.t('offerblock-formfield-type-textarea-plaintext'),
            },
            {
              value: 'TEXTAREA_RICHTEXT',
              label: i18next.t('offerblock-formfield-type-textarea-richtext'),
            },
          ]}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        {i18next.t('offerblock-formfield-usage')}
        {watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.type`) === 'TEXTAREA_RICHTEXT' && (
          <em>{`{{{contentBlock.formFields.${watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.name`) || ''}}}}`}</em>
        )}
        {watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.type`) !== 'TEXTAREA_RICHTEXT' && (
          <em>{`{{contentBlock.formFields.${watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.name`) || ''}}}`}</em>
        )}
      </Grid>
      <Grid item xs={6}>
        <FormInputText
          name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.name`}
          label={i18next.t('offerblock-formfield-name')}
          control={control}
          required
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInputText
          name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.label`}
          label={i18next.t('offerblock-formfield-label')}
          control={control}
          required
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        {type === 'TEXTAREA_RICHTEXT' && (
          <FormInputRichText
            options={{
              content_style: cssStyles,
            }}
            name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.defaultValue`}
            label={i18next.t('offerblock-formfield-defaultvalue')}
            control={control}
            required
            disabled={disabled}
          />
        )}
        {type === 'TEXTAREA_PLAINTEXT' && (
          <FormInputText
            name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.defaultValue`}
            label={i18next.t('offerblock-formfield-defaultvalue')}
            control={control}
            textFieldProps={{
              multiline: true,
              rows: 3,
            }}
            required
            disabled={disabled}
          />
        )}
        {type === 'TEXT' && (
          <FormInputText
            name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.defaultValue`}
            label={i18next.t('offerblock-formfield-defaultvalue')}
            control={control}
            required
            disabled={disabled}
          />
        )}
      </Grid>
      {false && (
        <Grid item xs={12}>
          <SimpleAccordion header="Erweiterte Formularfeldeinstellungen">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormInputCheckbox
                  name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.required`}
                  label={i18next.t('offerblock-formfield-required')}
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputText
                  name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.placeholder`}
                  label={i18next.t('offerblock-formfield-placeholder')}
                  control={control}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputText
                  name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.helpText`}
                  label={i18next.t('offerblock-formfield-helptext')}
                  control={control}
                  required
                />
              </Grid>
            </Grid>
          </SimpleAccordion>
        </Grid>
      )}
    </Grid>
  );
}

interface IEditorAccordionDummyProps {
  cssStyles: string;
  contentBlock: OfferTemplateContentBlock;
  index: number;
  control: any;
  disabled?: boolean;
  onDelete?: (index: number) => void;
}

export function EditorAccordionDummy({ cssStyles, contentBlock, index, control, disabled, onDelete }: IEditorAccordionDummyProps) {
  const [tabValue, setTabValue] = React.useState<string | null>('data');

  const {
    watch,
    formState: { errors: validationErrors },
  } = useFormContext<OfferTemplateInput>();

  const {
    fields: formFieldsFields,
    append: formFieldsAppend,
    remove: formFieldsRemove,
    replace: formFieldsReplace,
  } = useFieldArray({
    control,
    name: `contentBlocks.${index}.formFields`,
  });

  const _reassignSequence = (formFields: OfferTemplateFormField[]) => {
    formFields.forEach((li, index) => {
      li.sequence = index + 1;
    });
    formFieldsReplace(formFields);
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>
            {contentBlock.type === 'CUSTOM_FORM' && (
              <>
                <span>
                  {i18next.t('offerblock-customform-header')} #{contentBlock.sequence}
                </span>
                <br />
                <span>
                  <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                </span>
              </>
            )}
            {contentBlock.type === 'PLAIN_HTML' && (
              <>
                <span>
                  {i18next.t('offerblock-plainhtml-header')} #{contentBlock.sequence}
                </span>
                <br />
                <span>
                  <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                </span>
              </>
            )}
            {contentBlock.type === 'LINE_ITEMS' && (
              <>
                <span>
                  {i18next.t('offerblock-lineitems-header')} #{contentBlock.sequence}
                </span>
                <br />
                <span>
                  <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                </span>
              </>
            )}
            {contentBlock.type === 'CANCELLATION_ITEMS' && (
              <>
                <span>
                  {i18next.t('offerblock-cancellationitems-header')} #{contentBlock.sequence}
                </span>
                <br />
                <span>
                  <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                </span>
              </>
            )}
            {contentBlock.type === 'INPUT_DAYS' && (
              <>
                <span>
                  {i18next.t('offerblock-inputdays-header')} #{contentBlock.sequence}
                </span>
                <br />
                <span>
                  <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                </span>
              </>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {contentBlock.type === 'CUSTOM_FORM' && (
                <>
                  <Tabs value={tabValue}>
                    <Tab value="data" label={i18next.t('offerblock-customform-data-tab')} onClick={() => setTabValue('data')} />
                    <Tab value="template" label={i18next.t('offerblock-customform-template-tab')} onClick={() => setTabValue('template')} />
                  </Tabs>
                  {tabValue === 'template' && (
                    <Grid container>
                      <Grid item xs={12}>
                        <FormInputText
                          name={`contentBlocks.${index}.template`}
                          label={i18next.t('offerblock-customform-html')}
                          required
                          textFieldProps={{
                            multiline: true,
                            rows: 10,
                          }}
                          control={control}
                          disabled={disabled}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {tabValue === 'data' && (
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <br />
                        <FormInputText
                          name={`contentBlocks.${index}.blockDescription`}
                          label={i18next.t('offerblock-description')}
                          control={control}
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DragDropContext
                          onDragEnd={result => {
                            if (result.destination && contentBlock.lineItems) {
                              const formFields = watch(`contentBlocks.${index}.formFields`);
                              if (!formFields) return;
                              const [reorderedItem] = formFields.splice(result.source.index, 1);
                              formFields.splice(result.destination.index, 0, reorderedItem);
                              _reassignSequence(formFields);
                            }
                          }}
                        >
                          <Droppable droppableId={`characters_${index}`}>
                            {(provided: any) => (
                              <Grid container spacing={3} {...provided.droppableProps} ref={provided.innerRef} className={`characters_${index}`}>
                                {formFieldsFields.map((li, formFieldIndex) => (
                                  <Draggable
                                    key={`${li.id}_s2`}
                                    draggableId={`${index}_${formFieldIndex}_s2`}
                                    index={formFieldIndex}
                                    isDragDisabled={disabled}
                                  >
                                    {(provided: any) => (
                                      <Grid item xs={12} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <Accordion>
                                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                            <Typography>
                                              {watch(`contentBlocks.${index}.formFields.${formFieldIndex}.label`)} -{' '}
                                              {watch(`contentBlocks.${index}.formFields.${formFieldIndex}.name`)}
                                              <br />
                                              <span>
                                                <em>
                                                  {i18next.t(
                                                    `enums-EOfferVersionContentBlockFormFieldType-${watch(
                                                      `contentBlocks.${index}.formFields.${formFieldIndex}.type`,
                                                    )}`,
                                                  )}
                                                </em>
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <EditorAccordionFormFieldDummy
                                              cssStyles={cssStyles}
                                              contentBlock={contentBlock}
                                              contentBlockIndex={index}
                                              formFieldIndex={formFieldIndex}
                                              control={control}
                                              disabled={disabled}
                                            />
                                            {!disabled && (
                                              <Button
                                                onClick={() => {
                                                  formFieldsRemove(formFieldIndex);
                                                }}
                                                startIcon={<DeleteIcon />}
                                              >
                                                {i18next.t('offerblock-formfield-delete')}
                                              </Button>
                                            )}
                                          </AccordionDetails>
                                        </Accordion>
                                      </Grid>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </Grid>
                            )}
                          </Droppable>
                        </DragDropContext>
                        {!disabled && (
                          <Button
                            onClick={() => {
                              formFieldsAppend({
                                type: 'TEXT',
                                label: '',
                                sequence: 0,
                              });
                            }}
                          >
                            {i18next.t('offerblock-formfield-append')}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {contentBlock.type === 'PLAIN_HTML' && (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <br />
                    <FormInputText
                      name={`contentBlocks.${index}.blockDescription`}
                      label={i18next.t('offerblock-description')}
                      control={control}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputRichText
                      name={`contentBlocks.${index}.template`}
                      label={i18next.t('offerblock-plainhtml-html')}
                      required
                      options={{
                        content_style: cssStyles,
                      }}
                      control={control}
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
              )}
              {(contentBlock.type === 'LINE_ITEMS' || contentBlock.type === 'CANCELLATION_ITEMS') && (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <br />
                    <FormInputText
                      name={`contentBlocks.${index}.blockDescription`}
                      label={i18next.t('offerblock-description')}
                      control={control}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      name={`contentBlocks.${index}.template`}
                      label={i18next.t('offerblock-plainhtml-html')}
                      required
                      textFieldProps={{
                        multiline: true,
                        rows: 10,
                      }}
                      control={control}
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
              )}
              {contentBlock.type === 'INPUT_DAYS' && (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <br />
                    <FormInputText
                      name={`contentBlocks.${index}.blockDescription`}
                      label={i18next.t('offerblock-description')}
                      control={control}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      name={`contentBlocks.${index}.template`}
                      label={i18next.t('offerblock-plainhtml-html')}
                      required
                      textFieldProps={{
                        multiline: true,
                        rows: 10,
                      }}
                      control={control}
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
              )}

              {!disabled && onDelete && (
                <Button onClick={() => onDelete(index)} startIcon={<DeleteIcon />}>
                  {i18next.t('offerblock-delete')}
                </Button>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
