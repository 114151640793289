import _, { merge } from "lodash"


export interface ICancellationRule {
  daysToEvent: number
  minCapacity?: number | null
  maxCapacity?: number | null
  minOvernight?: number | null
  maxOvernight?: number | null
  minTotalGuests?: number | null
  maxTotalGuests?: number | null
  rate: number,
  toleranceRate?: number | null
}

/*
Kundenstornobedingungen Österreich
Premium: kleiner 7 Kalendertage vor Veranstaltungsbeginn (um 00:00) 100% der vereinbarten Leistung Toleranz 10%
Premium: kleiner 21 Kalendertage vor Veranstaltungsbeginn (um 00:00) 50% der vereinbarten Leistung Toleranz 10%

Kundenstornobedingungen Deutschland
Premium: kleiner 7 Kalendertage über 50 % der Zimmerkapazität oder über 30 Teilnehmer gebucht → 100% der vereinbarten Leistung, Toleranz 10%
Premium: kleiner 7 Kalendertage 100% der vereinbarten Leistung Toleranz 10%
Premium: kleiner 28 Kalendertage über 50 % der Zimmerkapazität oder über 30 Teilnehmer gebucht → 50% der vereinbarten Leistung, Toleranz 10%
Premium: kleiner 28 Kalendertage 50% der vereinbarten Leistung Toleranz 10%
*/

export const listPremiumClientCancellationRules = (businessCountry: string): ICancellationRule[] => {
  if (businessCountry === 'AT') {
    return [
      {
        daysToEvent: 7,
        rate: 1,
        toleranceRate: 0.1
      },
      {
        daysToEvent: 28, // soll 21 sein
        rate: 0.5,
        toleranceRate: 0.1
      }
    ]
  } else {
    return [
      {
        daysToEvent: 7,
        rate: 1,
        toleranceRate: 0.1
      },
      {
        daysToEvent: 28,
        rate: 0.5,
        toleranceRate: 0.1
      }
    ]
  }


}

// Kundenstornobedingungen Österreich & Deutschland
// Vermittlung: kleiner 21 Kalendertage und über 50 % der Zimmerkapazität oder über 50 Teilnehmer gebucht → 100% der vereinbarten Leistung, Toleranz 0%
// Vermittlung: kleiner 21 Kalendertage 100% der vereinbarten Leistung Toleranz 0%
// Vermittlung: kleiner 42 Kalendertage und über 50 % der Zimmerkapazität oder über 50 Teilnehmer gebucht → 50% der vereinbarten Leistung Toleranz 0%
// Vermittlung: kleiner 42 Kalendertage 50% der vereinbarten Leistung Toleranz 0%
export const listDefaultClientCancellationRules = (businessCountry: string): ICancellationRule[] => {
  return [
    {
      daysToEvent: 21,
      rate: 1
    },
    {
      daysToEvent: 42,
      rate: 0.5,
    }
  ]
}

export const getCancellationRules = (isPartnerHotel: boolean, businessCountry: string, isPremiumClient: boolean, hotelRules: ICancellationRule[], offerRules: ICancellationRule[]): ICancellationRule[] => {
  if (isPremiumClient) {
    if (isPartnerHotel) {
      const defaultRules = listPremiumClientCancellationRules(businessCountry)

      const fOfferRules = offerRules.filter(r => (r.minCapacity && r.minCapacity >= 0.5) || (r.minOvernight && r.minOvernight >= 30))
      if (fOfferRules.length > 0) return mergeCancellationRules(defaultRules, fOfferRules)

      return defaultRules
    } else {
      if (offerRules.length > 0) return offerRules;
      if (hotelRules.length > 0) return hotelRules;
      return listPremiumClientCancellationRules(businessCountry)
    }
  } else {
    if (offerRules.length > 0) return offerRules;
    if (hotelRules.length > 0) return hotelRules;
    return listDefaultClientCancellationRules(businessCountry)
  }
}

export const mergeCancellationRules = (defaultRules: ICancellationRule[], ...specificRuleSets: ICancellationRule[][]): ICancellationRule[] => {
  const result = [...defaultRules]
  for (const specificRules of specificRuleSets) {
    for (const rule of specificRules) {
      const ri = result.findIndex(er => er.daysToEvent === rule.daysToEvent)
      if (ri >= 0) {
        result[ri] = rule
      } else {
        result.push(rule)
      }
    }
  }
  return _.orderBy(result, r => r.daysToEvent, "asc")
}
