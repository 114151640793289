import React from 'react';
import { formatPrice as formatPriceShared } from 'semshared/utils/format';
interface PriceProps {
  price: number;
  currency: string;
}

export const formatPrice = (price: number, language: string = 'de-AT', currency: string = 'EUR') => {
  return formatPriceShared(price, language, currency)
}

export default function Price(props: PriceProps) {
  const { price, currency, ...rest } = props;

  return <>{formatPriceShared(price, currency)}</>;
}
