import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, InputAdornment } from '@mui/material';
import { FormCurrencyInputProps } from './FormInputProps';
import { allCurrenciesByCode } from 'languages';
import { styled } from '@mui/material';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
}));

export const FormInputCurrency = ({ name, control, label, textFieldProps, currency, disabled, emptyValue }: FormCurrencyInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <StyledTextField
          sx={{
            maxWidth: 200,
          }}
          helperText={disabled ? ' ' : error ? error.message : ' '}
          error={disabled ? false : !!error}
          onChange={event => {
            const { value } = event.target;
            if (value.length > 0) {
              const floatValue = Math.round(parseFloat(value.toString()) * 100) / 100;
              onChange(floatValue);
            } else {
              onChange(emptyValue === undefined ? null : emptyValue);
            }
          }}
          value={value !== null && value !== undefined ? (emptyValue !== undefined && emptyValue === value) ? '' : Math.round(value * 100) / 100 : ''}
          type="number"
          fullWidth
          InputLabelProps={{
            ...(disabled ? { shrink: true } : {}),
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {(currency && allCurrenciesByCode[currency] && allCurrenciesByCode[currency].symbol) || currency}
              </InputAdornment>
            ),
          }}
          label={label}
          disabled={!!disabled}
          {...textFieldProps}
        />
      )}
    />
  );
};
