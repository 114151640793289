import React, { useState } from 'react';
import _ from 'lodash';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button, Grid, CircularProgress, Divider, Typography, TextField, Box, InputAdornment, IconButton, Checkbox } from '@mui/material';

import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CodeIcon from '@mui/icons-material/Code';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PeopleIcon from '@mui/icons-material/People';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { getFullBackendUrl } from 'helper/download';

import { CustomTabsControlled } from 'components/Tabs';
import SimpleTable from 'components/table/SimpleTable';

import ConfirmationButton from 'components/dialogs/ConfirmationButton';

import {
  OFFERVERSION_APPROVE_MUTATION,
  OFFERVERSION_REJECT_MUTATION,
  OFFER_VIEW_QUERY,
  OFFER_AND_VERSION_VIEW_QUERY,
  REFETCH_OFFER_QUERIES,
  EVICT_OFFER_QUERIES,
  OFFERVERSION_PROCESSEVENTSTARTED_MUTATION,
  OFFERVERSION_PROCESSEVENTENDED_MUTATION,
  EVENT_VIEW_QUERY,
} from './gql';
import {
  INTEGRATION_SYNCBOOKING_MUTATION,
  INTEGRATION_CONFIRMBOOKING_MUTATION,
  INTEGRATION_DELETEBOOKING_MUTATION,
  INTEGRATION_GROUPBOOKINGINFO_VIEW_QUERY,
  EVICT_INTEGRATION_HOTELLOG_QUERIES,
} from '../settings/integration/gql';

import { AMEventDetailsOutputFragment, AMEventOutput, EAMRoomType, EOfferSource, EOfferStatus, EOfferVersionContentBlockType, EOfferVersionStatus, EOfferVersionType, ViewWlOfferQuery, ViewWlOfferVersionQuery, WLOfferListOutput, WLOfferListViewFragment, WLOfferVersionDiffOutputFragment, WLOfferVersionListOutput, WLOfferVersionListViewFragment } from '__generated__/graphql';

import OfferVersion from './editor';
import SimpleAccordion from 'components/SimpleAccordion';
import { FormatDate, FormatDateTime, formatDate } from 'components/DateTime';

import { IntegrationLogsTable } from '../settings/integration/logs';
import InformationDialog from 'components/dialogs/InformationDialog';

import { canBookingOpenPMS, canBookingSendPMS, canBookingConfirmPMS, canBookingDeletePMS } from 'pages/settings/integration/pmssupport';
import { RedirectError } from 'pages/error';
import { formatDocumentTitle } from 'helper/usedocumenttitle';
import { NO_PRICE } from 'semshared/pricelist/quickprice';
import { userSelector } from 'helper/security';
import { formatOfferDay } from 'semshared/utils/format';

interface OfferProps {
  id: number;
  versionId: number;
}

interface OfferLatestProps {
  id: number;
}

interface OfferFormProps {
  data: WLOfferListViewFragment;
  version: WLOfferVersionListViewFragment & WLOfferVersionDiffOutputFragment
  event: AMEventDetailsOutputFragment | null
}

function OfferForm(props: OfferFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = userSelector()!;

  const [approveOfferVersion] = useMutation(OFFERVERSION_APPROVE_MUTATION);
  const [rejectOfferVersion] = useMutation(OFFERVERSION_REJECT_MUTATION);
  const [processEventStartedMutateFunction, { loading: processEventStartedMutateLoading }] = useMutation(OFFERVERSION_PROCESSEVENTSTARTED_MUTATION);
  const [processEventEndedMutateFunction, { loading: processEventEndedMutateLoading }] = useMutation(OFFERVERSION_PROCESSEVENTENDED_MUTATION);
  const [syncMutateFunction, { loading: syncMutateLoading }] = useMutation(INTEGRATION_SYNCBOOKING_MUTATION);
  const [confirmMutateFunction, { loading: confirmMutateLoading }] = useMutation(INTEGRATION_CONFIRMBOOKING_MUTATION);
  const [deleteMutateFunction, { loading: deleteMutateLoading }] = useMutation(INTEGRATION_DELETEBOOKING_MUTATION);
  const [viewInfoQuery, { loading: viewInfoLoading }] = useLazyQuery(INTEGRATION_GROUPBOOKINGINFO_VIEW_QUERY);

  const offerSource = props.data.source
  const offerStatus = props.data.status
  const versionStatus = props.version.status
  const versionType = props.version.type

  const canDirectApprove =
    (versionType === EOfferVersionType.OFFER && offerStatus === EOfferStatus.PENDING && (versionStatus === EOfferVersionStatus.WAITFORHOTELAPPROVAL || versionStatus === EOfferVersionStatus.WAITFORHOTELRESERVATION))
    ||
    (versionType === EOfferVersionType.OFFER && offerStatus === EOfferStatus.PENDING && versionStatus === EOfferVersionStatus.WAITFORCLIENTAPPROVAL && (user.isSeminargo || offerSource === EOfferSource.WIDGET))
    ||    
    (versionType === EOfferVersionType.EDITING && offerStatus === EOfferStatus.APPROVED && versionStatus === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL)

  const canReject = 
    (versionType === EOfferVersionType.OFFER && offerStatus === EOfferStatus.PENDING && (versionStatus === EOfferVersionStatus.WAITFORHOTELAPPROVAL || versionStatus == EOfferVersionStatus.WAITFORCLIENTAPPROVAL || versionStatus === EOfferVersionStatus.WAITFORHOTELRESERVATION))
    ||
    (versionType === EOfferVersionType.EDITING && offerStatus === EOfferStatus.APPROVED && versionStatus === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL)

  const canChange = 
    (versionType === EOfferVersionType.OFFER && offerStatus === EOfferStatus.PENDING && versionStatus === EOfferVersionStatus.WAITFORHOTELAPPROVAL)
    ||
    (versionType === EOfferVersionType.OFFER && offerStatus === EOfferStatus.PENDING && versionStatus === EOfferVersionStatus.WAITFORCLIENTAPPROVAL && user.isSeminargo)
    ||
    (versionType === EOfferVersionType.RESERVATION_CONFIRMATION && offerStatus === EOfferStatus.APPROVED && versionStatus === EOfferVersionStatus.APPROVED)
    ||
    (versionType === EOfferVersionType.EDITING && offerStatus === EOfferStatus.APPROVED && versionStatus === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL)

  const canChangeStructure = offerSource === EOfferSource.WIDGET

  const canDownload = 
    (versionType === EOfferVersionType.OFFER)
    ||
    (versionType === EOfferVersionType.RESERVATION_CONFIRMATION && offerStatus === EOfferStatus.APPROVED && versionStatus === EOfferVersionStatus.APPROVED)

  
  const [hasNoPrices] = useState(props.version.totalPriceGross === NO_PRICE || !!props.version.contentBlocks.filter(cb => cb.type === EOfferVersionContentBlockType.LINE_ITEMS).find(cb => cb.lineItems.find(li => li.priceItem === NO_PRICE)))
  const [selectedTab, setSelectedTab] = React.useState(location.hash === '#editor' ? 1 : hasNoPrices ? 1 : props.version.status === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL ? 2 : 0);
  const [logUpdateCounter, setLogUpdateCounter] = useState(0);
  const [viewExtRefData, setViewExtRefData] = useState<string | null | undefined>(null);
  
  const [editing, setEditing] = useState(canChange && hasNoPrices);
  const [savePrices, setSavePrices] = useState(true);
  const [canEditorSave, setCanEditorSave] = useState(false);
  const [isEditorValid, setIsEditorValid] = useState(true);
  const [submitTrigger, setSubmitTrigger] = useState(0)

  const _approveButtonLabel = () => {
    if (editing) {
      if (props.version.status === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL) return i18next.t('offer-process-approve-cr-change')
      if (props.version.status === EOfferVersionStatus.WAITFORCLIENTAPPROVAL) return i18next.t('offer-process-approve-as-client-change')
      if (props.version.status === EOfferVersionStatus.APPROVED) return i18next.t('offer-process-approve-approved-change')
      return i18next.t('offer-process-approve-change')
    } else {
      if (props.version.status === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL) return i18next.t('offer-process-approve-cr')
      if (props.version.status === EOfferVersionStatus.WAITFORCLIENTAPPROVAL) return i18next.t('offer-process-approve-as-client')
      if (props.version.status === EOfferVersionStatus.WAITFORHOTELRESERVATION) return i18next.t('offer-process-approve-reservation')
      return i18next.t('offer-process-approve-offer')
    }
  }
  const _approveQuestionTitle = () => {
    if (props.version.status === EOfferVersionStatus.APPROVED) return i18next.t('offerconfirm-approve-approved-title')
    if (props.version.status === EOfferVersionStatus.WAITFORHOTELRESERVATION) return i18next.t('offerconfirm-approve-reservation-title')
    return i18next.t('offerconfirm-approve-offer-title')
  }

  const _approveQuestion = () => {
    if (editing) {
      if (props.version.status === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL) return i18next.t('offerconfirm-approve-cr-question-change')
      if (props.version.status === EOfferVersionStatus.WAITFORCLIENTAPPROVAL) return i18next.t('offerconfirm-approve-as-client-question-change')
      if (props.version.status === EOfferVersionStatus.APPROVED) return i18next.t('offerconfirm-approve-approved-question-change')
      return i18next.t('offerconfirm-approve-question-change')
    } else {
      if (props.version.status === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL) return i18next.t('offerconfirm-approve-cr-question')
      if (props.version.status === EOfferVersionStatus.WAITFORCLIENTAPPROVAL) return i18next.t('offerconfirm-approve-as-client-question')
      if (props.version.status === EOfferVersionStatus.WAITFORHOTELRESERVATION) return i18next.t('offerconfirm-approve-reservation-question')
      return i18next.t('offerconfirm-approve-offer-question')
    }
  }
  
  const _rejectButtonLabel = () => {
    if (props.version.status === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL) return i18next.t('offer-process-reject-cr')
    if (props.version.status === EOfferVersionStatus.WAITFORHOTELRESERVATION) return i18next.t('offer-process-reject-reservation')
    return i18next.t('offer-process-reject-offer')
  }
  const _rejectQuestion = () => {
    return i18next.t('offerconfirm-reject-text')
  }

  const setShowOfferVersionId = (versionId: number) => {
    navigate(`/offers/${props.data.id}/${versionId}#editor`);
  };

  const _canSeeCustomerData = () => {
    if (props.data.source === EOfferSource.WIDGET) return true;
    if (user.isSeminargo || user.isRoot) return true;
    if (props.data.status === EOfferStatus.APPROVED) return true;
    return false;
  }

  const eventDays = _.orderBy((props.event?.eventDays || []).filter(d => d.day >= 0), d => d.day, 'asc')
  const attendanceDays = _.uniq((props.event?.attendances || []).reduce<number[]>((agg, a) => [...agg, ...a.days.map(d => d.day)], [])).sort()
  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle([i18next.t(`offers-list-page-title-${offerSource}`), props.data])}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {props.data.refCode} - {i18next.t(`enums-EOfferVersionStatus-${props.version.status}`)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {!editing && canDirectApprove && <ConfirmationButton
            sx={{ marginRight: 2 }}
            disabled={hasNoPrices}
            variant="contained"
            confirmationQuestion={_approveQuestion()}
            confirmationTitle={_approveQuestionTitle()}
            startIcon={<ThumbUpIcon />}
            onConfirm={async () => {
              try {
                const res = await approveOfferVersion({
                  variables: {
                    offerVersionId: props.version.id,
                  },
                  update: cache => {
                    EVICT_OFFER_QUERIES(cache);
                    EVICT_INTEGRATION_HOTELLOG_QUERIES(cache);
                  },
                  awaitRefetchQueries: true,
                  refetchQueries: REFETCH_OFFER_QUERIES(props.data.id, props.version.id),
                });
                dispatchMessage(dispatch, i18next.t('offer-status-approved'));
                setShowOfferVersionId(res.data!.approveOfferVersion.id!)
              } catch (err) {
                dispatchException(dispatch, err);
              }
            }}
          >
            {_approveButtonLabel()}
          </ConfirmationButton>}
          {editing && <ConfirmationButton
            sx={{ marginRight: 2 }}
            disabled={!canEditorSave}
            variant="contained"
            confirmationQuestion={<Grid container>
              <Grid item xs={12}>
                {_approveQuestion()}
              </Grid>
              {hasNoPrices && <Grid item xs={12}>
                <Checkbox checked={savePrices} onChange={() => setSavePrices(!savePrices)} /> {i18next.t('offerconfirm-approve-offer-save-prices')}
              </Grid>}
            </Grid>}
            confirmationTitle={_approveQuestionTitle()}
            startIcon={<ThumbUpIcon />}
            onConfirm={async () => {
              setSubmitTrigger(submitTrigger + 1)
            }}
          >
            {_approveButtonLabel()}
          </ConfirmationButton>}

          {canReject && <ConfirmationButton
            sx={{ marginRight: 2 }}
            disabled={editing}
            variant="contained"
            color="secondary"
            confirmationQuestion={_rejectQuestion()}
            confirmationTitle={i18next.t('offerconfirm-reject-title')}
            startIcon={<ThumbDownIcon />}
            onConfirm={async () => {
              try {
                await rejectOfferVersion({
                  variables: {
                    offerVersionId: props.version.id,
                  },
                  update: cache => {
                    EVICT_OFFER_QUERIES(cache);
                    EVICT_INTEGRATION_HOTELLOG_QUERIES(cache);
                  },
                  awaitRefetchQueries: true,
                  refetchQueries: REFETCH_OFFER_QUERIES(props.data.id, props.version.id),
                });
                dispatchMessage(dispatch, i18next.t('offer-status-rejected'));
              } catch (err) {
                dispatchException(dispatch, err);
              }
            }}
          >
            {_rejectButtonLabel()}
          </ConfirmationButton>}

          {canChange && !editing && 
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              color="secondary"
              startIcon={<EditIcon />}
              onClick={() => {
                setEditing(true)
                setSelectedTab(1);
              }}
            >
              {offerStatus === 'APPROVED' && i18next.t('offer-confirmation-process-edit')}
              {offerStatus !== 'APPROVED' && i18next.t('offer-process-edit')}
            </Button>
          }
          {canChange && editing &&
            <Button
              sx={{ marginRight: 2 }}
              disabled={!canChange}
              variant="contained"
              color="secondary"
              startIcon={<EditIcon />}
              onClick={() => {
                setEditing(false)
              }}
            >
              {i18next.t('offer-process-edit-cancel')}
            </Button>
          }
          {canDownload && !editing &&       
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              color="secondary"
              startIcon={<DownloadIcon />}
              onClick={() => {
                window.open(getFullBackendUrl(`/backend/pdf/downloadOffer/${props.version.id}`), '_blank');
              }}
            >
              {offerStatus === 'APPROVED' && i18next.t('offer-confirmation-process-download')}
              {offerStatus !== 'APPROVED' && i18next.t('offer-process-download')}
            </Button>
          }
        </Grid>
        <Grid item xs={12}>
          <CustomTabsControlled
            selected={selectedTab}
            handleChange={selected => setSelectedTab(selected)}
            headers={[
              i18next.t('offer-input-tab'),
              editing ? i18next.t('offer-editor-tab-change') : offerStatus === EOfferStatus.APPROVED ? i18next.t('offer-confirmation-editor-tab') : i18next.t('offer-editor-tab'),
              i18next.t('offer-approvecr-tab'),
              i18next.t('offer-integration-tab'),
              i18next.t('offer-event-tab'),
              i18next.t('offer-history-tab'),
            ]}
            disabled={[editing, false, false, editing, editing, editing]}
            hidden={[false, false, props.version.status !== EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL, props.data.hotel.integrations.length === 0, props.event === null, false]}
            icons={[undefined, isEditorValid ? undefined : <ErrorIcon />, <ErrorIcon />, undefined, undefined, undefined ]}
            tabs={[
              <Grid container>
                {!props.version.input && (
                  <Grid item xs={6}>
                    Nicht vorhanden
                  </Grid>
                )}
                {props.version.input && (<>
                  <Grid item xs={6}>
                    <Grid container>
                      {_canSeeCustomerData() && <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={8} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-email')}</Typography>
                            {props.version.client.email}
                          </Grid>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-phone')}</Typography>
                            {props.version.client.phone || '-'}
                          </Grid>
                        </Grid>
                      </Grid>}
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-start')}</Typography>
                            <FormatDate date={props.version.input!.startDate} />
                          </Grid>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-end')}</Typography>
                            <FormatDate date={props.version.input!.endDate} />
                          </Grid>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-servicetype')}</Typography>
                            {props.version.input!.serviceType?.header}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-comment')}</Typography>
                            {props.version.input!.comment || '-'}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sx={{ padding: 1 }}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sx={{ padding: 1 }}>
                        <Typography variant="h5">{i18next.t('offer-input-header-company-data')}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                        {_canSeeCustomerData() && <><Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-name')}</Typography>
                            {props.version.client.firstname} {props.version.client.lastname}
                          </Grid></>}
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-company')}</Typography>
                            {props.version.client.company}
                          </Grid>
                          {_canSeeCustomerData() && <><Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-address')}</Typography>
                            {props.version.client.address}
                            <br />
                            {props.version.client.zip} {props.version.client.city}
                            <br />
                            {props.version.client.country}
                          </Grid></>}
                        </Grid>
                      </Grid>
                      {_canSeeCustomerData() && <><Grid item xs={12} sx={{ padding: 1 }}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sx={{ padding: 1 }}>
                        <Typography variant="h5">{i18next.t('offer-input-header-billing-data')}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-name')}</Typography>
                            {props.version.client.billingFirstname} {props.version.client.billingLastname}
                          </Grid>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-company')}</Typography>
                            {props.version.client.billingCompany}
                          </Grid>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-address')}</Typography>
                            {props.version.client.billingAddress}
                            <br />
                            {props.version.client.billingZip} {props.version.client.billingCity}
                            <br />
                            {props.version.client.billingCountry}
                          </Grid>
                        </Grid>
                      </Grid></>}

                      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12} sx={{ padding: 1 }}>
                        <SimpleAccordion autoOpen={true} header={<>{i18next.t('offer-input-header-preday')}</>}>
                          <Grid container>
                            <Grid item xs={4}>
                              <Typography variant="subtitle2">{i18next.t('offer-input-header-guests')}</Typography>
                              {props.version.input!.prevdayGuests}
                            </Grid>
                          </Grid>
                        </SimpleAccordion>
                      </Grid>
                      {props.version.input!.days.map((day, index) => (
                        <Grid key={index} item xs={12} sx={{ padding: 1 }}>
                          <SimpleAccordion
                            autoOpen={true}
                            header={
                              <>
                                {i18next.t('offer-input-header-day')} {day.day + 1}
                              </>
                            }
                          >
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item xs={4} sx={{ padding: 1 }}>
                                    <Typography variant="subtitle2">{i18next.t('offer-input-header-guests')}:</Typography> {day.totalGuests}
                                  </Grid>
                                  <Grid item xs={4} sx={{ padding: 1 }}>
                                    <Typography variant="subtitle2">{i18next.t('offer-input-header-overnightguests')}:</Typography>{' '}
                                    {day.overnightGuests}
                                  </Grid>
                                  <Grid item xs={12} sx={{ padding: 1 }}>
                                    <Typography variant="subtitle2">{i18next.t('offer-input-header-rooms')}:</Typography>
                                    <SimpleTable
                                      headers={[
                                        i18next.t('offer-occupancy-header-room'),
                                        i18next.t('offer-occupancy-header-occupancy'),
                                        i18next.t('offer-occupancy-header-seating'),
                                      ]}
                                      rows={day.occupancy.map((row, index) => [
                                        row.room,
                                        i18next.t(`enums-EOfferRoomOccupancy-${row.occupancy}`),
                                        i18next.t(`enums-EOfferRoomSeating-${row.seating}`),
                                      ])}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sx={{ padding: 1 }}>
                                    <Typography variant="subtitle2">{i18next.t('offer-input-header-addons')}:</Typography>
                                    {day.addons.length === 0 && <Typography variant="body2">{i18next.t('offer-input-no-addons')}</Typography>}
                                    {day.addons.length > 0 && (
                                      <SimpleTable
                                        headers={[i18next.t('offer-addons-header-name'), i18next.t('offer-addons-header-count')]}
                                        rows={day.addons.map((row, index) => [
                                          <>
                                            {row.product && <Link to={`/products/product/${row.product.id}`}>{row.product.name}</Link>}
                                            {row.facility && <Link to={`/products/facility/${row.facility.id}`}>{row.facility.name}</Link>}
                                          </>,
                                          row.count,
                                        ])}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </SimpleAccordion>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </>)}
              </Grid>,
              <OfferVersion showClientData={_canSeeCustomerData()} versionId={props.version.id} offerId={props.data.id} onShowOfferVersion={id => setShowOfferVersionId(id)}
                canChange={editing && canChange}
                canChangeStructure={canChangeStructure}
                onChangeIsValid={(isValid) => setIsEditorValid(isValid)}
                onChangeCanSaveState={(canSave) => setCanEditorSave(canSave)}
                submitTrigger={submitTrigger}
                savePrices={savePrices}
              />,
              <Grid container>
                {props.version.diffToApproved && (
                  <Grid item xs={6}>
                    <Grid container>
                      {props.version.diffToApproved!.startDateChanged && <Grid item xs={12}>
                        {i18next.t('offer-input-changed-startdate', {
                          fromStartDate: formatDate(props.version.diffToApproved!.startDateChanged.fromStartDate),
                          toStartDate: formatDate(props.version.diffToApproved!.startDateChanged.toStartDate)
                        })}
                      </Grid>}
                      {props.version.diffToApproved!.diff.map((dayDiff, index) => (
                        <Grid key={index} item xs={12} sx={{ padding: 1 }}>
                          <SimpleAccordion
                            autoOpen={true}
                            header={
                              <>
                                {dayDiff.day === -1 && i18next.t('offer-input-header-preday')}
                                {dayDiff.day >= 0 && <>{i18next.t('offer-input-header-day')} {dayDiff.day + 1}</>} 
                              </>
                            }
                          >
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item xs={12} sx={{ padding: 1 }}>
                                    <SimpleTable
                                      headers={[
                                        i18next.t('offer-input-line-sku'),
                                        i18next.t('offer-input-line-header'),
                                        i18next.t('offer-input-line-count1'),
                                        i18next.t('offer-input-line-count2'),
                                        i18next.t('offer-input-line-diff'),
                                      ]}
                                      rows={dayDiff.diffs.map((row, index) => [
                                        row.sku,
                                        row.header,
                                        row.count1,
                                        row.count2,
                                        row.diff > 0 ? `+${row.diff}`: row.diff
                                      ])}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </SimpleAccordion>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>                  
                )}
              </Grid>,
              <Grid container>
                <Grid item xs={12} sm={9}>
                  <Button
                    sx={{ marginRight: 2 }}
                    variant="contained"
                    color="secondary"
                    startIcon={viewInfoLoading ? <CircularProgress size={24} /> : <OpenInNewIcon />}
                    disabled={!canBookingOpenPMS(props.version) || viewInfoLoading}
                    onClick={async () => {
                      try {
                        const res = await viewInfoQuery({
                          variables: {
                            offerVersionId: props.version.id,
                          },
                        });
                        if (res.data?.integrationViewGroupBookingInfo && res.data?.integrationViewGroupBookingInfo.href) {
                          window.open(res.data?.integrationViewGroupBookingInfo.href, '_blank');
                        } else {
                          dispatchMessage(dispatch, i18next.t('offer-process-openbooking-nohref'));
                        }
                      } catch (err) {
                        dispatchException(dispatch, err);
                      }
                    }}
                  >
                    {i18next.t('offer-process-openbooking')}
                  </Button>
                  <Button
                    sx={{ marginRight: 2 }}
                    variant="contained"
                    color="secondary"
                    startIcon={syncMutateLoading ? <CircularProgress size={24} /> : <SyncIcon />}
                    disabled={!canBookingSendPMS(props.version) || syncMutateLoading}
                    onClick={async () => {
                      try {
                        const res = await syncMutateFunction({
                          variables: {
                            offerVersionId: props.version.id,
                          },
                          update: cache => EVICT_INTEGRATION_HOTELLOG_QUERIES(cache),
                          refetchQueries: REFETCH_OFFER_QUERIES(props.data.id, props.version.id),
                        });
                        if (res.data?.integrationSyncBooking) {
                          if (res.data?.integrationSyncBooking.status === 'SUCCESS') {
                            dispatchMessage(dispatch, i18next.t('offer-process-syncbooking-success'));
                          }
                          setLogUpdateCounter(logUpdateCounter + 1);
                        }
                      } catch (err) {
                        dispatchException(dispatch, err);
                      }
                    }}
                  >
                    {i18next.t('offer-process-syncbooking')}
                  </Button>
                  <Button
                    sx={{ marginRight: 2 }}
                    variant="contained"
                    color="secondary"
                    startIcon={confirmMutateLoading ? <CircularProgress size={24} /> : <CheckIcon />}
                    disabled={!canBookingConfirmPMS(props.version) || confirmMutateLoading}
                    onClick={async () => {
                      try {
                        const res = await confirmMutateFunction({
                          variables: {
                            offerVersionId: props.version.id,
                          },
                          update: cache => EVICT_INTEGRATION_HOTELLOG_QUERIES(cache),
                          refetchQueries: REFETCH_OFFER_QUERIES(props.data.id, props.version.id),
                        });
                        if (res.data?.integrationConfirmBooking) {
                          if (res.data?.integrationConfirmBooking.status === 'SUCCESS') {
                            dispatchMessage(dispatch, i18next.t('offer-process-confirmbooking-success'));
                          }
                          setLogUpdateCounter(logUpdateCounter + 1);
                        }
                      } catch (err) {
                        dispatchException(dispatch, err);
                      }
                    }}
                  >
                    {i18next.t('offer-process-confirmbooking')}
                  </Button>
                  <Button
                    sx={{ marginRight: 2 }}
                    variant="contained"
                    color="secondary"
                    startIcon={deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />}
                    disabled={!canBookingDeletePMS(props.version) || deleteMutateLoading}
                    onClick={async () => {
                      try {
                        const res = await deleteMutateFunction({
                          variables: {
                            offerVersionId: props.version.id,
                          },
                          update: cache => EVICT_INTEGRATION_HOTELLOG_QUERIES(cache),
                          refetchQueries: REFETCH_OFFER_QUERIES(props.data.id, props.version.id),
                        });
                        if (res.data?.integrationDeleteBooking) {
                          if (res.data?.integrationDeleteBooking.status === 'SUCCESS') {
                            dispatchMessage(dispatch, i18next.t('offer-process-deletebooking-success'));
                          }
                          setLogUpdateCounter(logUpdateCounter + 1);
                        }
                      } catch (err) {
                        dispatchException(dispatch, err);
                      }
                    }}
                  >
                    {i18next.t('offer-process-deletebooking')}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label={i18next.t('offer-process-extrefcode')}
                    value={props.version.extRefCode || ''}
                    disabled
                    InputProps={{
                      endAdornment: props.version.extRefData && (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setViewExtRefData(props.version.extRefData)}>
                            <CodeIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ padding: 1 }}></Grid>
                <Grid item xs={12}>
                  <IntegrationLogsTable key={logUpdateCounter} variables={{ spaceId: props.data.space.id, offerId: props.data.id }} />
                </Grid>
                <InformationDialog
                  title={i18next.t('offer-process-extrefdata')}
                  open={!!viewExtRefData}
                  onConfirm={() => setViewExtRefData(null)}
                  maxWidth="lg"
                  fullWidth
                >
                  <Box p={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <pre>{viewExtRefData && JSON.stringify(JSON.parse(viewExtRefData), null, 2)}</pre>
                      </Grid>
                    </Grid>
                  </Box>
                </InformationDialog>
              </Grid>,
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SimpleAccordion header={`${i18next.t('offer-event-mealtimes')}`} autoOpen>
                    <SimpleTable
                      headers={[
                        i18next.t('offer-event-mealtimes-day'),
                        i18next.t('offer-event-mealtimes-ek'),
                        i18next.t('offer-event-mealtimes-kp1'),
                        i18next.t('offer-event-mealtimes-me'),
                        i18next.t('offer-event-mealtimes-kp2'),
                        i18next.t('offer-event-mealtimes-ae'),
                        i18next.t('offer-event-mealtimes-ez'),
                        i18next.t('offer-event-mealtimes-dz'),
                      ]}
                      rows={eventDays.map((day, dindex) => [
                        <>{formatOfferDay(day.day)}</>,
                        <>{day.morningTime} - {(props.event?.attendances || []).reduce((sum, a) => sum + (a.days.findIndex(d => d.day === day.day && d.morning) >= 0 ? 1 : 0), 0)} <PeopleIcon/></>,
                        <>{day.forenoonTime} - {(props.event?.attendances || []).reduce((sum, a) => sum + (a.days.findIndex(d => d.day === day.day && d.forenoon) >= 0 ? 1 : 0), 0)} <PeopleIcon/></>,
                        <>{day.lunchTime} - {(props.event?.attendances || []).reduce((sum, a) => sum + (a.days.findIndex(d => d.day === day.day && d.lunch) >= 0 ? 1 : 0), 0)} <PeopleIcon/></>,
                        <>{day.afternoonTime} - {(props.event?.attendances || []).reduce((sum, a) => sum + (a.days.findIndex(d => d.day === day.day && d.afternoon) >= 0 ? 1 : 0), 0)} <PeopleIcon/></>,
                        <>{day.dinnerTime} - {(props.event?.attendances || []).reduce((sum, a) => sum + (a.days.findIndex(d => d.day === day.day && d.dinner) >= 0 ? 1 : 0), 0)} <PeopleIcon/></>,
                        <>{(props.event?.attendances || []).reduce((sum, a) => sum + (a.days.findIndex(d => d.day === day.day && d.room === EAMRoomType.ROOM_SINGLE) >= 0 ? 1 : 0), 0)} <PeopleIcon/></>,
                        <>{(props.event?.attendances || []).reduce((sum, a) => sum + (a.days.findIndex(d => d.day === day.day && d.room === EAMRoomType.ROOM_DOUBLE) >= 0 ? 1 : 0), 0)} <PeopleIcon/></>,
                      ])}
                    />
                  </SimpleAccordion>
                </Grid>
                <Grid item xs={12}>
                  <SimpleAccordion header={`${i18next.t('offer-event-rooms')}`} autoOpen>
                    {eventDays.map((eventDay, edindex) => <SimpleTable
                      key={edindex}
                      headers={[
                        formatOfferDay(eventDay.day),
                        ...eventDay.rooms.map((r, rindex) => i18next.t('offer-event-rooms-number', { roomNumber: rindex + 1 }))
                      ]}
                      rows={[
                        [
                          i18next.t('offer-event-rooms-duration'),
                          ...eventDay.rooms.map((r, rindex) => <React.Fragment key={rindex}>{r.fromTime} - {r.toTime}</React.Fragment>)
                        ],
                        [
                          i18next.t('offer-event-rooms-seating'),
                          ...eventDay.rooms.map((r, rindex) => <React.Fragment key={rindex}>{i18next.t(`enums-EOfferRoomSeating-${r.seating}`)}</React.Fragment>)
                        ],
                        [
                          i18next.t('offer-event-rooms-guests'),
                          ...eventDay.rooms.map((r, rindex) => <React.Fragment key={rindex}>{r.guestCount} <PeopleIcon/></React.Fragment>)
                        ],
                        [
                          i18next.t('offer-event-rooms-label'),
                          ...eventDay.rooms.map((r, rindex) => <React.Fragment key={rindex}>{r.label}</React.Fragment>)
                        ],
                        [
                          i18next.t('offer-event-rooms-facilities'),
                          ...eventDay.rooms.map((r, rindex) => <React.Fragment key={rindex}>{r.commentFacilities}</React.Fragment>)
                        ]
                      ]}
                    />)}
                  </SimpleAccordion>
                </Grid>
                <Grid item xs={12}>
                  <SimpleAccordion header={`${i18next.t('offer-event-attendances')}`} autoOpen>
                    {attendanceDays.map((aDay, adindex) => <SimpleTable
                      key={adindex}
                      headers={[
                        formatOfferDay(aDay),
                        i18next.t('offer-event-attendances-ek'),
                        i18next.t('offer-event-attendances-kp1'),
                        i18next.t('offer-event-attendances-me'),
                        i18next.t('offer-event-attendances-kp2'),
                        i18next.t('offer-event-attendances-ae'),
                        i18next.t('offer-event-attendances-ez'),
                        i18next.t('offer-event-attendances-dz'),
                        i18next.t('offer-event-attendances-coach'),
                        i18next.t('offer-event-attendances-dpservice'),
                        i18next.t('offer-event-attendances-dproom'),
                        i18next.t('offer-event-attendances-dpextra'),
                        i18next.t('offer-event-attendances-dpparking'),
                      ]}
                      rows={(props.event?.attendances || []).map((a, aindex) => {
                        const ad = a.days.find(ad => ad.day === aDay)
                        if (ad) {
                          return [
                            <>{a.profile.lastname} {a.profile.firstname}</>,
                            <>{ad.morning && <CheckIcon />}</>,
                            <>{ad.forenoon && <CheckIcon />}</>,
                            <>{ad.lunch && <CheckIcon />}</>,
                            <>{ad.afternoon && <CheckIcon />}</>,
                            <>{ad.dinner && <CheckIcon />}</>,
                            <>{ad.room === EAMRoomType.ROOM_SINGLE && <CheckIcon />}</>,
                            <>{ad.room === EAMRoomType.ROOM_DOUBLE && <CheckIcon />}</>,
                            <>{ad.coach && <CheckIcon />}</>,
                            <>{(!_.isNull(ad.serviceDirectPay) ? ad.serviceDirectPay : !_.isNull(a.serviceDirectPay) ? a.serviceDirectPay: !_.isNull(props.event?.serviceDirectPay) ? props.event?.serviceDirectPay! : false)  && <CheckIcon />}</>,
                            <>{(!_.isNull(ad.roomDirectPay) ? ad.roomDirectPay : !_.isNull(a.roomDirectPay) ? a.roomDirectPay: !_.isNull(props.event?.roomDirectPay) ? props.event?.roomDirectPay! : false)  && <CheckIcon />}</>,
                            <>{(!_.isNull(ad.extraDirectPay) ? ad.extraDirectPay : !_.isNull(a.extraDirectPay) ? a.extraDirectPay: !_.isNull(props.event?.extraDirectPay) ? props.event?.extraDirectPay! : false)  && <CheckIcon />}</>,
                            <>{(!_.isNull(ad.parkingDirectPay) ? ad.parkingDirectPay : !_.isNull(a.parkingDirectPay) ? a.parkingDirectPay: !_.isNull(props.event?.parkingDirectPay) ? props.event?.parkingDirectPay! : false)  && <CheckIcon />}</>,
                          ]
                        }
                        return null
                      }).filter(r => r).map(r => r!)}
                    />)}
                  </SimpleAccordion>
                </Grid>
                <Grid item xs={12}>
                  <SimpleAccordion header={`${i18next.t('offer-event-comments')}`} autoOpen>
                    <SimpleTable
                      headers={[
                        i18next.t('offer-event-comments-accomodation'),
                        i18next.t('offer-event-comments-payment'),
                        i18next.t('offer-event-comments-rooms'),
                        i18next.t('offer-event-comments-other'),
                      ]}
                      rows={[[
                        props.event?.commentAccomodation,
                        props.event?.commentPayment,
                        props.event?.commentRooms,
                        props.event?.commentOther
                      ]]}
                    />
                  </SimpleAccordion>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{ marginRight: 2 }}
                    variant="contained"
                    color="secondary"
                    startIcon={<DownloadIcon />}
                    onClick={() => {
                      window.open(getFullBackendUrl(`/backend/pdf/downloadChecklist/${props.event?.id}`), '_blank');
                    }}
                  >
                    {i18next.t('offer-event-tab-download')}
                  </Button>
                </Grid>
              </Grid>,                 
              <Grid container>
                <Grid item xs={12}>
                  {versionType === EOfferVersionType.RESERVATION_CONFIRMATION && versionStatus === EOfferVersionStatus.APPROVED && offerStatus === EOfferStatus.APPROVED &&
                    <Button
                      variant="text"
                      onClick={async () => {
                        try {
                          const res = await processEventStartedMutateFunction({
                            variables: {
                              offerVersionId: props.version.id,
                            },
                            refetchQueries: REFETCH_OFFER_QUERIES(props.data.id, props.version.id),
                          });
                          navigate(`/offers/${offerSource}`);
                        } catch (err) {
                          dispatchException(dispatch, err);
                        }
                      }}
                    >
                      {i18next.t('offer-process-eventstarted')}
                    </Button>
                  }
                  {versionType === EOfferVersionType.RESERVATION_CONFIRMATION && versionStatus === EOfferVersionStatus.APPROVED && (offerStatus === EOfferStatus.APPROVED || offerStatus === EOfferStatus.ACTIVE) &&
                    <Button
                      variant="text"
                      onClick={async () => {
                        try {
                          const res = await processEventEndedMutateFunction({
                            variables: {
                              offerVersionId: props.version.id,
                            },
                            refetchQueries: REFETCH_OFFER_QUERIES(props.data.id, props.version.id),
                          });
                          navigate(`/offers/${offerSource}`);
                        } catch (err) {
                          dispatchException(dispatch, err);
                        }
                      }}
                    >
                      {i18next.t('offer-process-eventended')}
                    </Button>
                  }                  
                </Grid>
                <Grid item xs={12}>
                  <SimpleTable
                    headers={[
                      i18next.t('offer-versions-header-createdat'),
                      i18next.t('offer-versions-header-version'),
                      i18next.t('offer-versions-header-status'),
                      i18next.t('offer-versions-header-user'),
                      '',
                    ]}
                    rows={_.orderBy(
                      [
                        ...props.data.history.map(h => ({
                          createdAt: h.createdAt,
                          version: h.offerVersion ? h.offerVersion.version : '',
                          status: i18next.t(`enums-EOfferVersionHistoryStatus-${h.status}`),
                          username: h.user?.name,
                          action: h.extraInfo ? <>{h.extraInfo}</> : h.offerVersion ? (
                            <Button
                              variant="text"
                              onClick={() => {
                                setShowOfferVersionId(h.offerVersion!.id)
                              }}
                            >
                              {i18next.t('offer-history-view')}
                            </Button>
                          ) : null,
                        })),
                        ...props.data.emailHistory
                          .filter(e => e.success)
                          .map(e => ({
                            createdAt: e.createdAt,
                            version: e.offerVersion ? e.offerVersion.version : '',
                            status: i18next.t('offer-history-email-sent'),
                            username: e.userName,
                            action: (
                              <Grid container>
                                <Grid item xs={12}>
                                  {i18next.t('offer-history-email-subject')}: <b>{e.subject}</b>
                                </Grid>
                                <Grid item xs={12}>
                                  {i18next.t('offer-history-email-from')}: {e.from}
                                </Grid>
                                <Grid item xs={12}>
                                  {i18next.t('offer-history-email-to')}: {e.to}
                                </Grid>
                              </Grid>
                            ),
                          })),
                        ...props.data.emailHistory
                          .filter(e => !e.success)
                          .map(e => ({
                            createdAt: e.createdAt,
                            version: e.offerVersion ? e.offerVersion.version : '',
                            status: i18next.t('offer-history-email-failed'),
                            username: e.userName,
                            action: (
                              <Grid container>
                                <Grid item xs={12}>
                                  {i18next.t('offer-history-email-subject')}: <b>{e.subject}</b>
                                </Grid>
                                <Grid item xs={12}>
                                  {i18next.t('offer-history-email-from')}: {e.from}
                                </Grid>
                                <Grid item xs={12}>
                                  {i18next.t('offer-history-email-to')}: {e.to}
                                </Grid>
                                <Grid item xs={12}>
                                  {i18next.t('offer-history-email-err')}: {e.err}
                                </Grid>
                              </Grid>
                            ),
                          })),
                      ],
                      l => l.createdAt,
                      'desc',
                    ).map((row, index) => [
                      <FormatDateTime date={row.createdAt} />,
                      row.version || null,
                      row.status || null,
                      row.username || null,
                      row.action || null,
                    ])}
                  />
                </Grid>
              </Grid>
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default function Offer(props: OfferProps) {
  const offerQuery = useQuery(OFFER_AND_VERSION_VIEW_QUERY, {
    variables: { id: props.id, versionId: props.versionId }
  });
  const eventQuery = useQuery(EVENT_VIEW_QUERY, {
    variables: { offerId: props.id }
  })

  const loading = offerQuery.loading || eventQuery.loading;
  const error = offerQuery.error || eventQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else if (!offerQuery.data?.viewWlOffer || !offerQuery.data?.viewWlOfferVersion) return <Navigate to={`/offers`} />
  else 
    return (
      <OfferForm data={offerQuery.data!.viewWlOffer!} version={offerQuery.data!.viewWlOfferVersion!} event={eventQuery.data?.viewWlOfferEvent || null} />
    );
}

export function OfferLatest(props: OfferLatestProps) {
  const offerQuery = useQuery(OFFER_VIEW_QUERY, {
    variables: { id: props.id },
    fetchPolicy: 'network-only',
  });
  const eventQuery = useQuery(EVENT_VIEW_QUERY, {
    variables: { offerId: props.id }
  })

  const loading = offerQuery.loading || eventQuery.loading;
  const error = offerQuery.error || eventQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else if (!offerQuery.data?.viewWlOffer || !offerQuery.data?.viewWlOfferLatestVersion) return <Navigate to={`/offers`} />

  else
    return (
      <OfferForm
        data={offerQuery.data!.viewWlOffer!}
        version={offerQuery.data!.viewWlOfferLatestVersion!}
        event={eventQuery.data?.viewWlOfferEvent || null}
      />
    );
}
