import React from 'react';

interface PercentageProps {
  percentage: number;
}

export function formatPercentage(percentage: number, precision: number = 2) {
  return `${(percentage * 100).toFixed(precision)}%`
}

export default function Percentage(props: PercentageProps) {
  const { percentage, ...rest } = props;

  return <>{(percentage * 100).toFixed(2)}%</>;
}
