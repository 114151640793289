import _ from 'lodash';
import moment from 'moment';
import { TaxRateSelector, ITaxRate, ITaxType } from './taxselector';
import { PriceListSelector, PriceListInput, IPriceList } from './pricelistselector';
import { PriceSelector, PriceSelectorInput, IPriceSelector, IPriceRule, PriceSelectorOutput } from './priceselector';
import { roundPrice } from '../utils/round';

export const NO_PRICE = -100000

const EPricing_ProductPayment = {
  BYGUESTANDDAY: 'BYGUESTANDDAY',
  BYCOUNT: 'BYCOUNT',
  BYGUEST: 'BYGUEST',
  BYROOMANDDAY: 'BYROOMANDDAY',
  BYROOM: 'BYROOM'
};
const EPricing_FacilityPayment = {
  BYCOUNT: 'BYCOUNT',
  BYDAY: 'BYDAY',
  BYGUEST: 'BYGUEST'
};
const EPricing_FacilityLookupCode = {
  SEMINARROOM_FULLDAY: 'SEMINARROOM_FULLDAY',
  SEMINARROOM_SEMIDAY: 'SEMINARROOM_SEMIDAY',
};
const EWL_OfferRoomOccupancy = {
  FULLDAY: 'FULLDAY',
  MORNING: 'MORNING',
  AFTERNOON: 'AFTERNOON'
};

export interface IQuickPriceInput {
  comment?: string | null;
  title?: string | null;
  days: IQuickPriceDay[];
  endDate: Date;
  language: string;
  prevdayGuests: number;
  serviceTypeSku?: string | null;
  startDate: Date;
}
export interface IQuickPriceAddon {
  count?: number | null;
  lookupCode?: string | null;
  sku?: string | null;
}
export interface IQuickPriceDay {
  addonFacilities?: IQuickPriceAddon[] | null;
  addonProducts?: IQuickPriceAddon[] | null;
  day: number;
  occupancy: string[];
  overnightGuests: number;
  seating: string[];
  totalGuests: number;
}

export type CalcPriceOutput<
  PriceListType extends IPriceList,
  HotelType extends IHotel,
  ServiceTypeType extends IServiceType,
  FacilityType extends IFacility,
  ProductType extends IProduct,
  ProductBundleType extends IProductBundle,
> = {
  priceList: PriceListType;
  serviceType: ServiceTypeType;
  hotel: HotelType;
  totalPriceNet: number;
  totalPriceGross: number;
  totalTaxes: number;
  taxes: CalcTax[];
  lineItems: CalcLineItem<FacilityType, ProductType, ProductBundleType>[];
};

export type CalcLineItem<FacilityType extends IFacility, ProductType extends IProduct, ProductBundleType extends IProductBundle> = {
  day: number;
  sku: string;
  name: string;
  product?: ProductType | null;
  bundle?: ProductBundleType | null;
  facility?: FacilityType | null;
  selector?: IPriceSelector | null;
  isIncluded: boolean;
  count: number;
  priceItem: number;
  priceGross: number;
  priceNet: number;
  components: CalcPriceComponent[];
  taxes: CalcTax[];
};

export type CalcPriceComponent = {
  type: ITaxType;
  price: number;
};

export type CalcTax = {
  type: ITaxType;
  rate: ITaxRate;
  price: number;
};

export interface IHotel {
  id: number;
  businessCountry: string;
}

export interface IPrice {
  price: number | null;
  bundlePriceFromProduct?: boolean | null;
  components?: IPriceComponent[];
  selector?: IPriceSelector | null;
}
export interface IPriceComponent {
  taxTypeId: number;
  price: number;
}

export interface IFacility {
  id: number;
  name: string;
  sku: string;
  lookupCode: string | null;
  recurring: string;
  components: IComponent[];
}
export interface IProduct {
  id: number;
  name: string;
  sku: string;
  lookupCode: string | null;
  isDeduction: boolean;
  recurring: string;
  components: IComponent[];
}
export interface IComponent {
  taxTypeId: number;
}
export interface IId {
  id: number;
  sku: string;
}
export interface IProductBundle {
  id: number;
  name: string;
  sku: string;
  lookupCode: string | null;
  recurring: string;
  components: IComponent[];
  bundleItems: IProductBundleItem[];
}
export interface IProductBundleItem {
  productId: number;
  includedCount: number;
}

export interface IServiceType {
  id: number;
  name: string;
  sku: string;
  products: IServiceTypeProduct[];
  facilities: IServiceTypeFacility[];
  addons: IServiceTypeAddon[];
}
export interface IServiceTypeProduct {
  isForDayVisitor: boolean;
  isForDeparture: boolean;
  isForOvernight: boolean;
  isForSemidayVisitor: boolean;
  isForSemidayOvernight: boolean;
  isForSemidayDeparture: boolean;
  isForNextday: boolean;
  isForRoom: boolean;
  isForPrevday: boolean;
  bundle: IId | null;
  product: IId | null;
}
export interface IServiceTypeFacility {
  facility: IId;
  isForDay: boolean;
  isForSemiday: boolean;
  includedCount: number;
}
export interface IServiceTypeAddon {
  approvalLimit?: number | null;
  product: IId | null;
  facility: IId | null;
}
export interface ICalcOptions {
  addonsOnly?: boolean;
  allowNoPrices?: boolean;
  priceSelector?: (sel: PriceSelectorInput) => Omit<PriceSelectorOutput<IPriceSelector>, 'selector'>
}

export class QuickPriceError extends Error {
  readonly extensions: any | undefined;
  constructor(message: string, extensions?: any | undefined) {
    super(message);
    this.extensions = extensions;
  }
}

export type CalcPriceOutputType = CalcPriceOutput<IPriceList, IHotel, IServiceType, IFacility, IProduct, IProductBundle>;
export type CalcLineItemType = CalcLineItem<IFacility, IProduct, IProductBundle>;
export type QuickPriceCalculatorType = QuickPriceCalculator<IPriceList, IHotel, IServiceType, IFacility, IProduct, IProductBundle>;

export class QuickPriceCalculator<
  PriceListType extends IPriceList,
  HotelType extends IHotel,
  ServiceTypeType extends IServiceType,
  FacilityType extends IFacility,
  ProductType extends IProduct,
  ProductBundleType extends IProductBundle,
> {
  public taxRateSelector: TaxRateSelector<ITaxRate, ITaxType> | null = null;

  public hotel: HotelType | null = null;

  public allProductsDb: ProductType[] | null = null;
  public allFacilitiesDb: FacilityType[] | null = null;
  public allBundlesDb: ProductBundleType[] | null = null;
  public allServiceTypesDb: ServiceTypeType[] | null = null;

  public priceListSelector: PriceListSelector<PriceListType> | null = null;
  public priceSelector: PriceSelector<IPriceSelector, IPriceRule> | null = null;

  public hydrate(json: string) {
    const { allProductsDb, allFacilitiesDb, allBundlesDb, allServiceTypesDb, hotel, priceListSelector, priceSelector, taxRateSelector } =
      JSON.parse(json);

    this.taxRateSelector = new TaxRateSelector();
    this.taxRateSelector.hydrate(taxRateSelector);

    this.hotel = hotel;

    this.allProductsDb = allProductsDb;
    this.allFacilitiesDb = allFacilitiesDb;
    this.allBundlesDb = allBundlesDb;
    this.allServiceTypesDb = allServiceTypesDb;

    this.priceListSelector = new PriceListSelector();
    if (priceListSelector) {
      this.priceListSelector.hydrate(priceListSelector as string);
    }
    this.priceSelector = new PriceSelector();
    if (priceSelector) {
      this.priceSelector.hydrate(priceSelector as string);
    }
  }
  public dehydrate(): string {
    return JSON.stringify({
      taxRateSelector: this.taxRateSelector?.dehydrate(),
      hotel: this.hotel,
      allProductsDb: this.allProductsDb,
      allFacilitiesDb: this.allFacilitiesDb,
      allBundlesDb: this.allBundlesDb,
      allServiceTypesDb: this.allServiceTypesDb,
      priceListSelector: this.priceListSelector?.dehydrate(),
      priceSelector: this.priceSelector?.dehydrate(),
    });
  }

  public getPriceList(input: PriceListInput) {
    const priceList = this.priceListSelector?.findPriceList(input);
    if (priceList) {
      return priceList;
    } else {
      throw new QuickPriceError(`No pricelist found for ${input.date}`, {
        extensions: { code: 'NO_PRICELIST' },
      });
    }
  }

  public calculatePrice(
    input: IQuickPriceInput,
    calcOptions?: ICalcOptions
  ): CalcPriceOutput<PriceListType, HotelType, ServiceTypeType, FacilityType, ProductType, ProductBundleType> {
    const startMom = moment(input.startDate);
    const endMom = moment(input.endDate);

    const days = endMom.diff(startMom, 'days') + 1;
    for (let dayNum = 0; dayNum < days; dayNum++) {
      if (input.days.findIndex(d => d.day === dayNum) < 0) {
        throw new QuickPriceError(`No selection found for day ${dayNum}`, {
          extensions: { code: 'BAD_INPUT' },
        });
      }
    }

    const initialPriceList = this.getPriceList({
      date: startMom.toDate()
    });

    const allProductsDb = this.allProductsDb!;
    const allFacilitiesDb = this.allFacilitiesDb!;
    const allBundlesDb = this.allBundlesDb!;
    const allServiceTypesDb = this.allServiceTypesDb!;

    const serviceType = allServiceTypesDb.find(st => st.sku === input.serviceTypeSku)!;

    //TODO check minVisitors/maxVisitors etc

    const result: CalcPriceOutput<PriceListType, HotelType, ServiceTypeType, FacilityType, ProductType, ProductBundleType> = {
      priceList: initialPriceList,
      serviceType: serviceType!,
      hotel: this.hotel!,
      totalPriceGross: 0.0,
      totalPriceNet: 0.0,
      totalTaxes: 0.0,
      taxes: [],
      lineItems: [],
    };

    const _mergeLineItem = (lineItem: CalcLineItem<FacilityType, ProductType, ProductBundleType>) => {
      const existingLineItem = result.lineItems.find(
        l =>
          ((l.day === null && lineItem.day === null) || l.day === lineItem.day) &&
          l.sku === lineItem.sku &&
          l.isIncluded === lineItem.isIncluded &&
          ((l.selector && lineItem.selector && l.selector.id === lineItem.selector.id) || (!l.selector && !lineItem.selector)),
      );
      if (existingLineItem) {
        existingLineItem.count += lineItem.count;
        if (existingLineItem.priceGross === NO_PRICE || lineItem.priceGross === NO_PRICE) existingLineItem.priceGross = NO_PRICE
        else existingLineItem.priceGross += lineItem.priceGross;
        if (existingLineItem.priceNet === NO_PRICE || lineItem.priceNet === NO_PRICE) existingLineItem.priceNet = NO_PRICE
        else existingLineItem.priceNet += lineItem.priceNet;
        this.mergeTaxes(existingLineItem.taxes, lineItem.taxes);
      } else {
        result.lineItems.push({ ...lineItem });
      }
    };
    const _sumTotalPrices = () => {
      result.totalPriceGross = result.lineItems.reduce((sum, li) => (sum === NO_PRICE || li.priceGross === NO_PRICE ? NO_PRICE : sum + li.priceGross), 0);
      result.totalPriceNet = result.lineItems.reduce((sum, li) => (sum === NO_PRICE || li.priceNet === NO_PRICE ? NO_PRICE : sum + li.priceNet), 0);
      result.taxes = [];
      for (const lineItem of result.lineItems) {
        this.mergeTaxes(result.taxes, lineItem.taxes);
      }
      result.totalTaxes = (result.totalPriceGross === NO_PRICE || result.totalPriceNet === NO_PRICE) ? NO_PRICE : result.totalPriceGross - result.totalPriceNet;
    };

    const _isFullDay = (day: IQuickPriceDay) => !!day.occupancy.find(o => o === EWL_OfferRoomOccupancy.FULLDAY);

    const _filterServiceTypeProductsByPayment = (recurring: string) =>
      serviceType.products.filter(sp => {
        if (sp.product) {
          const p = allProductsDb.find(pd => pd.id === sp.product!.id);
          return p && p.recurring === recurring;
        }
        if (sp.bundle) {
          const b = allBundlesDb.find(pd => pd.id === sp.bundle!.id);
          return b && b.recurring === recurring;
        }
      });

    if (calcOptions?.addonsOnly === false || calcOptions?.addonsOnly === undefined) {
      const allDaysGuestsCount = input.days.reduce(
        (acc, day) => {
          acc += day.totalGuests;
          return acc;
        },
        0,
      );

      const recurringProducts = [..._filterServiceTypeProductsByPayment(EPricing_ProductPayment.BYGUESTANDDAY), ..._filterServiceTypeProductsByPayment(EPricing_ProductPayment.BYROOMANDDAY)];

      for (let dayNum = 0; dayNum < days; dayNum++) {
        const dayMom = moment(startMom).add(dayNum, 'days');
        const day = input.days.find(d => d.day === dayNum)!;
        const isFullDay = _isFullDay(day);

        const priceList = this.getPriceList({ date: dayMom.toDate() });

        const counts = {
          dayVisitorGuests: 0,
          overnightGuests: 0,
          roomGuests: 0,
          departureGuests: 0,
          nextdayGuests: 0,
        };
        if (dayNum === 0 && days === 1) {
          if (day.occupancy.length === 0) {
            counts.nextdayGuests = day.overnightGuests;
          } else {
            counts.dayVisitorGuests = day.totalGuests;
            counts.nextdayGuests = day.overnightGuests;
          }
        } else if (dayNum === days - 1) {
          counts.nextdayGuests = day.overnightGuests;

          if (day.occupancy.length > 0) {
            const prevDay = input.days.find(d => d.day === dayNum - 1)!;
            counts.departureGuests = Math.min(day.totalGuests, Math.max(0, prevDay.overnightGuests - day.overnightGuests));
            counts.dayVisitorGuests = Math.max(0, day.totalGuests - counts.departureGuests);
          }
        } else {
          if (day.occupancy.length === 0) {
            counts.roomGuests = day.overnightGuests;
          } else if (day.totalGuests < day.overnightGuests) {
            counts.overnightGuests = day.totalGuests;
            counts.roomGuests = day.overnightGuests - day.totalGuests;
          } else {
            counts.dayVisitorGuests = day.totalGuests - day.overnightGuests;
            counts.overnightGuests = day.overnightGuests;
          }
        }

        if (counts.dayVisitorGuests > 0) {
          const dayVisitorProducts = recurringProducts.filter(p => (isFullDay ? p.isForDayVisitor : p.isForSemidayVisitor));
          for (const p of dayVisitorProducts) {
            if (p.bundle) {
              const lineItem = this.getBundleLineItem(dayNum, {
                priceListId: priceList.id,
                bundleId: p.bundle.id,
                guestCount: day.totalGuests,
                itemCount: counts.dayVisitorGuests,
                date: dayMom.toDate()
              }, null, calcOptions);
              if (lineItem) _mergeLineItem(lineItem);
            }
            if (p.product) {
              const lineItem = this.getProductLineItem(dayNum, {
                priceListId: priceList.id,
                productId: p.product.id,
                guestCount: day.totalGuests,
                itemCount: counts.dayVisitorGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) _mergeLineItem(lineItem);
            }
          }
        }
        if (counts.departureGuests > 0) {
          const departureProduct = recurringProducts.filter(p => (isFullDay ? p.isForDeparture : p.isForSemidayDeparture));
          for (const p of departureProduct) {
            if (p.bundle) {
              const lineItem = this.getBundleLineItem(dayNum, {
                priceListId: priceList.id,
                bundleId: p.bundle.id,
                guestCount: day.totalGuests,
                itemCount: counts.departureGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) _mergeLineItem(lineItem);
            }
            if (p.product) {
              const lineItem = this.getProductLineItem(dayNum, {
                priceListId: priceList.id,
                productId: p.product.id,
                guestCount: day.totalGuests,
                itemCount: counts.departureGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) _mergeLineItem(lineItem);
            }
          }
        }
        if (counts.overnightGuests > 0) {
          const overnightProducts = recurringProducts.filter(p => (isFullDay ? p.isForOvernight : p.isForSemidayOvernight));
          for (const p of overnightProducts) {
            if (p.bundle) {
              const lineItem = this.getBundleLineItem(dayNum, {
                priceListId: priceList.id,
                bundleId: p.bundle.id,
                guestCount: day.totalGuests,
                itemCount: counts.overnightGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) _mergeLineItem(lineItem);
            }
            if (p.product) {
              const lineItem = this.getProductLineItem(dayNum, {
                priceListId: priceList.id,
                productId: p.product.id,
                guestCount: day.totalGuests,
                itemCount: counts.overnightGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) _mergeLineItem(lineItem);
            }
          }
        }
        if (counts.roomGuests > 0) {
          const roomProducts = recurringProducts.filter(p => p.isForRoom);
          for (const p of roomProducts) {
            if (p.bundle) {
              const lineItem = this.getBundleLineItem(dayNum, {
                priceListId: priceList.id,
                bundleId: p.bundle.id,
                guestCount: day.totalGuests,
                itemCount: counts.roomGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) _mergeLineItem(lineItem);
            }
            if (p.product) {
              const lineItem = this.getProductLineItem(dayNum, {
                priceListId: priceList.id,
                productId: p.product.id,
                guestCount: day.totalGuests,
                itemCount: counts.roomGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) _mergeLineItem(lineItem);
            }
          }
        }
        if (counts.nextdayGuests > 0) {
          const nextdayProducts = recurringProducts.filter(p => p.isForNextday);
          for (const p of nextdayProducts) {
            if (p.bundle) {
              const lineItem = this.getBundleLineItem(dayNum, {
                priceListId: priceList.id,
                bundleId: p.bundle.id,
                guestCount: day.totalGuests,
                itemCount: counts.nextdayGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) _mergeLineItem(lineItem);
            }
            if (p.product) {
              const lineItem = this.getProductLineItem(dayNum, {
                priceListId: priceList.id,
                productId: p.product.id,
                guestCount: day.totalGuests,
                itemCount: counts.nextdayGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) _mergeLineItem(lineItem);
            }
          }
        }
      }

      if (allDaysGuestsCount > 0) {
        const byCountProducts = _filterServiceTypeProductsByPayment(EPricing_ProductPayment.BYCOUNT);

        for (const p of byCountProducts) {
          const firstDay = input.days.find(d => d.day === 0)!;
          const priceList = this.getPriceList({ date: startMom.toDate() });

          if (p.bundle) {
            const lineItem = this.getBundleLineItem(0, {
              priceListId: priceList.id,
              bundleId: p.bundle.id,
              guestCount: firstDay.totalGuests,
              itemCount: 1,
              date: startMom.toDate(),
            }, null, calcOptions);
            if (lineItem) _mergeLineItem(lineItem);
          }
          if (p.product) {
            const lineItem = this.getProductLineItem(0, {
              priceListId: priceList.id,
              productId: p.product.id,
              guestCount: firstDay.totalGuests,
              itemCount: 1,
              date: startMom.toDate(),
            }, null, calcOptions);
            if (lineItem) _mergeLineItem(lineItem);
          }
        }
      }

      const byGuestProducts = [..._filterServiceTypeProductsByPayment(EPricing_ProductPayment.BYGUEST), ..._filterServiceTypeProductsByPayment(EPricing_ProductPayment.BYROOM)];

      for (const p of byGuestProducts) {
        const firstDay = input.days.find(d => d.day === 0)!;
        const priceList = this.getPriceList({ date: startMom.toDate() });

        if (p.bundle) {
          const lineItem = this.getBundleLineItem(0, {
            priceListId: priceList.id,
            bundleId: p.bundle.id,
            guestCount: firstDay.totalGuests,
            itemCount: firstDay.totalGuests,
            date: startMom.toDate(),
          }, null, calcOptions);
          if (lineItem) _mergeLineItem(lineItem);
        }
        if (p.product) {
          const lineItem = this.getProductLineItem(0, {
            priceListId: priceList.id,
            productId: p.product.id,
            guestCount: firstDay.totalGuests,
            itemCount: firstDay.totalGuests,
            date: startMom.toDate(),
          }, null, calcOptions);
          if (lineItem) _mergeLineItem(lineItem);
        }
      }

      if (input.prevdayGuests > 0) {
        const prevDay = moment(startMom).add(-1, 'days').toDate();
        const priceList = this.getPriceList({ date: prevDay });

        const prevdayProducts = recurringProducts.filter(p => p.isForPrevday);
        for (const p of prevdayProducts) {
          if (p.bundle) {
            const lineItem = this.getBundleLineItem(-1, {
              priceListId: priceList.id,
              bundleId: p.bundle.id,
              guestCount: input.prevdayGuests,
              itemCount: input.prevdayGuests,
              date: prevDay,
            }, null, calcOptions);
            if (lineItem) _mergeLineItem(lineItem);
          }
          if (p.product) {
            const lineItem = this.getProductLineItem(-1, {
              priceListId: priceList.id,
              productId: p.product.id,
              guestCount: input.prevdayGuests,
              itemCount: input.prevdayGuests,
              date: prevDay,
            }, null, calcOptions);
            if (lineItem) _mergeLineItem(lineItem);
          }
        }
      }
    }

    for (let dayNum = 0; dayNum < days; dayNum++) {
      const dayMom = moment(startMom).add(dayNum, 'days');
      const day = input.days.find(d => d.day === dayNum)!;

      const priceList = this.getPriceList({ date: dayMom.toDate() });

      for (const addon of day.addonProducts || []) {
        const addonProduct = addon.sku
          ? allProductsDb.find(p => p.sku === addon.sku)
          : addon.lookupCode
            ? allProductsDb.find(p => p.lookupCode === addon.lookupCode)
            : null;
        if (addonProduct) {
          addon.sku = addonProduct.sku;
          if (addonProduct.recurring === EPricing_ProductPayment.BYGUESTANDDAY || addonProduct.recurring === EPricing_ProductPayment.BYGUEST) {
            const lineItem = this.getProductLineItem(dayNum, {
              priceListId: priceList.id,
              productId: addonProduct.id,
              guestCount: day.totalGuests,
              itemCount: (addon.count || 1) * day.totalGuests,
              date: dayMom.toDate(),
            }, null, calcOptions);
            if (lineItem) _mergeLineItem(lineItem);
          } else if (addonProduct.recurring === EPricing_ProductPayment.BYROOMANDDAY || addonProduct.recurring === EPricing_ProductPayment.BYROOM) {
            const lineItem = this.getProductLineItem(dayNum, {
              priceListId: priceList.id,
              productId: addonProduct.id,
              guestCount: day.totalGuests,
              itemCount: (addon.count || 1) * day.overnightGuests,
              date: dayMom.toDate(),
            }, null, calcOptions);
            if (lineItem) _mergeLineItem(lineItem);
          } else if (addonProduct.recurring === EPricing_ProductPayment.BYCOUNT) {
            const lineItem = this.getProductLineItem(dayNum, {
              priceListId: priceList.id,
              productId: addonProduct.id,
              guestCount: day.totalGuests,
              itemCount: addon.count || 1,
              date: dayMom.toDate(),
            }, null, calcOptions);
            if (lineItem) _mergeLineItem(lineItem);
          }
          // } else {
          // throw new GraphQLError(`No price found for addon product ${addon.sku || addon.lookupCode}`, { extensions: { code: 'BAD_PRICELIST', product: addon.sku || addon.lookupCode } })
        }
      }
    }

    const _filterServiceTypeFacilitiesByPayment = (recurring: string) =>
      serviceType.facilities.filter(sp => {
        const f = allFacilitiesDb.find(fd => fd.id === sp.facility.id);
        return f && f.recurring === recurring;
      });

    if (calcOptions?.addonsOnly === false || calcOptions?.addonsOnly === undefined) {
      const recurringFacilities = _filterServiceTypeFacilitiesByPayment(EPricing_FacilityPayment.BYDAY);

      for (let dayNum = 0; dayNum < days; dayNum++) {
        const day = input.days.find(d => d.day === dayNum)!;
        const isFullDay = _isFullDay(day);

        const byDayFacilities = recurringFacilities.filter(p => (isFullDay ? p.isForDay : p.isForSemiday));

        for (const f of byDayFacilities) {
          const facility = allFacilitiesDb.find(fd => fd.id === f.facility.id)!;
          const lineItem: CalcLineItem<FacilityType, ProductType, ProductBundleType> = {
            day: dayNum,
            sku: facility.sku,
            name: facility.name,
            facility: facility,
            count: f.includedCount,
            isIncluded: true,
            priceItem: 0,
            priceGross: 0,
            priceNet: 0,
            components: [],
            taxes: [],
          };
          _mergeLineItem(lineItem);
        }
      }

      const byCountFacilities = _filterServiceTypeFacilitiesByPayment(EPricing_FacilityPayment.BYCOUNT);
      for (const f of byCountFacilities) {
        const facility = allFacilitiesDb.find(fd => fd.id === f.facility.id)!;
        const lineItem: CalcLineItem<FacilityType, ProductType, ProductBundleType> = {
          day: 0,
          sku: facility.sku,
          name: facility.name,
          facility: facility,
          count: f.includedCount || 1,
          isIncluded: true,
          priceItem: 0,
          priceGross: 0,
          priceNet: 0,
          components: [],
          taxes: [],
        };
        _mergeLineItem(lineItem);
      }

      const byGuestFacilities = _filterServiceTypeFacilitiesByPayment(EPricing_FacilityPayment.BYGUEST);

      for (const f of byGuestFacilities) {
        const firstDay = input.days.find(d => d.day === 0)!;
        const facility = allFacilitiesDb.find(fd => fd.id === f.facility.id)!;
        const lineItem: CalcLineItem<FacilityType, ProductType, ProductBundleType> = {
          day: 0,
          sku: facility.sku,
          name: facility.name,
          facility: facility,
          count: (f.includedCount || 1) * firstDay.totalGuests,
          isIncluded: true,
          priceItem: 0,
          priceGross: 0,
          priceNet: 0,
          components: [],
          taxes: [],
        };
        _mergeLineItem(lineItem);
      }
    }

    const seminarroomFacility = serviceType?.facilities.filter(f => f.isForDay).find(sp => {
      const f = allFacilitiesDb.find(fd => fd.id === sp.facility.id);
      return f && f.lookupCode === EPricing_FacilityLookupCode.SEMINARROOM_FULLDAY;
    });
    const seminarroomSemidayFacility = serviceType?.facilities.filter(f => f.isForSemiday).find(sp => {
      const f = allFacilitiesDb.find(fd => fd.id === sp.facility.id);
      return f && f.lookupCode === EPricing_FacilityLookupCode.SEMINARROOM_SEMIDAY;
    });
    const addonSeminarroomFacility = serviceType?.facilities.filter(f => f.includedCount === 0).find(sp => {
      const f = allFacilitiesDb.find(fd => fd.id === sp.facility.id);
      return f && f.lookupCode === EPricing_FacilityLookupCode.SEMINARROOM_FULLDAY;
    }) || seminarroomFacility;
    const addonSeminarroomSemidayFacility = serviceType?.facilities.filter(f => f.includedCount === 0).find(sp => {
      const f = allFacilitiesDb.find(fd => fd.id === sp.facility.id);
      return f && f.lookupCode === EPricing_FacilityLookupCode.SEMINARROOM_SEMIDAY;
    }) || seminarroomSemidayFacility || addonSeminarroomFacility;

    for (let dayNum = 0; dayNum < days; dayNum++) {
      const dayMom = moment(startMom).add(dayNum, 'days');
      const day = input.days.find(d => d.day === dayNum)!;

      const priceList = this.getPriceList({ date: dayMom.toDate() });

      const addonFacilities = [...(day.addonFacilities || [])];

      if (day.occupancy.length > 0) {
        const bookedFullCount = day.occupancy.filter(o => o === EWL_OfferRoomOccupancy.FULLDAY).length;
        const includedFullCount = (seminarroomFacility && seminarroomFacility.includedCount) || 0;
        const addonFullCount = Math.max(0, bookedFullCount - includedFullCount);
        if (addonSeminarroomFacility && addonFullCount > 0) {
          addonFacilities.push({
            sku: allFacilitiesDb.find(fd => fd.id === addonSeminarroomFacility.facility.id)!.sku,
            count: addonFullCount,
          });
        }
        const remainingFullCountIncluded = Math.max(0, includedFullCount - bookedFullCount);

        const bookedSemiCount = day.occupancy.filter(o => o !== EWL_OfferRoomOccupancy.FULLDAY).length;
        const includedSemiCount = (seminarroomSemidayFacility && seminarroomSemidayFacility.includedCount) || 0;
        const addonSemiCount = Math.max(0, bookedSemiCount - includedSemiCount - remainingFullCountIncluded);
        if (addonSeminarroomSemidayFacility && addonSemiCount > 0) {
          addonFacilities.push({
            sku: allFacilitiesDb.find(fd => fd.id === addonSeminarroomSemidayFacility.facility.id)!.sku,
            count: addonSemiCount,
          });
        }
      }
      for (const addon of addonFacilities) {
        const addonFacility = addon.sku
          ? allFacilitiesDb.find(f => f.sku === addon.sku)
          : addon.lookupCode
            ? allFacilitiesDb.find(f => f.lookupCode === addon.lookupCode)
            : null;
        if (addonFacility) {
          addon.sku = addonFacility.sku;
          if (addonFacility.recurring === EPricing_FacilityPayment.BYGUEST) {
            const lineItem = this.getFacilityLineItem(dayNum, {
              priceListId: priceList.id,
              facilityId: addonFacility.id,
              guestCount: day.totalGuests,
              itemCount: (addon.count || 1) * day.totalGuests,
              date: dayMom.toDate(),
            }, null, calcOptions);
            if (lineItem) _mergeLineItem(lineItem);
          } else if (addonFacility.recurring === EPricing_FacilityPayment.BYDAY || addonFacility.recurring === EPricing_FacilityPayment.BYCOUNT) {
            const lineItem = this.getFacilityLineItem(dayNum, {
              priceListId: priceList.id,
              facilityId: addonFacility.id,
              guestCount: day.totalGuests,
              itemCount: addon.count || 1,
              date: dayMom.toDate(),
            }, null, calcOptions);
            if (lineItem) _mergeLineItem(lineItem);
          }
          // } else {
          // throw new GraphQLError(`No price found for addon facility ${addon.sku || addon.lookupCode}`, { extensions: { code: 'BAD_PRICELIST', facility: addon.sku || addon.lookupCode } })
        }
      }
    }
    _sumTotalPrices();
    result.lineItems.sort((a, b) => (a.day === null ? -1 : b.day === null ? 1 : a.day - b.day));
    return result;
  }

  public mergeTaxes(destination: CalcTax[], taxes: CalcTax[]) {
    for (const tax of taxes) {
      const existingTax = destination.find(t => t.type.id === tax.type.id);
      if (existingTax) {
        if (existingTax.price === NO_PRICE || tax.price === NO_PRICE) existingTax.price = NO_PRICE
        else existingTax.price += tax.price;
      } else {
        destination.push({ ...tax });
      }
    }
    return _.orderBy(destination, d => d.type.sequence, 'asc');
  }
  public mergeComponents(destination: CalcPriceComponent[], components: CalcPriceComponent[]) {
    for (const component of components) {
      const existingComponent = destination.find(t => t.type.id === component.type.id);
      if (existingComponent) {
        if (existingComponent.price === NO_PRICE || existingComponent.price === NO_PRICE) existingComponent.price = NO_PRICE
        else existingComponent.price += component.price;
      } else {
        destination.push({ ...component });
      }
    }
    return _.orderBy(destination, d => d.type.sequence, 'asc');
  }

  public getFreeStyleLineItem(itemCount: number, price: number, isPricesNet: boolean, taxTypeId: number) {
    const taxRate = this.taxRateSelector?.getTaxRate({
      country: this.hotel!.businessCountry,
      date: new Date(),
      taxTypeId: taxTypeId,
    });
    if (!taxRate)
      throw new QuickPriceError(`No tax rate found for ${taxTypeId}`, {
        extensions: { code: 'BAD_PRICELIST', taxTypeId },
      });

    if (isPricesNet) {
      const priceNet = itemCount * price;
      const tax = {
        type: this.taxRateSelector?.getTaxType(taxTypeId)!,
        rate: taxRate,
        price: roundPrice(priceNet * taxRate.rate),
      };
      const priceGross = priceNet + tax.price;
      return {
        priceNet,
        priceGross,
        components: [
          {
            type: tax.type,
            price: price,
          },
        ],
        taxes: [tax],
      };
    } else {
      const priceGross = itemCount * price;
      const priceNet = roundPrice(priceGross / (1 + taxRate.rate));
      const tax = {
        type: this.taxRateSelector?.getTaxType(taxTypeId)!,
        rate: taxRate,
        price: priceGross - priceNet,
      };
      return {
        priceNet,
        priceGross,
        priceItem: price,
        components: [
          {
            type: tax.type,
            price: price,
          },
        ],
        taxes: [tax],
      };
    }
  }

  public getLookupCodeForSKU(sku: string) {
    return this.allBundlesDb?.find(b => b.sku === sku)?.lookupCode || this.allProductsDb?.find(p => p.sku === sku)?.lookupCode || this.allFacilitiesDb?.find(f => f.sku === sku)?.lookupCode
  }
  public getLookupCodesForSKUs(skus: string[]) {
    return skus.map(sku => this.getLookupCodeForSKU(sku))
  }

  public getSKUForLookupCode(code: string) {
    return this.allBundlesDb?.find(b => b.lookupCode === code)?.sku || this.allProductsDb?.find(p => p.lookupCode === code)?.sku || this.allFacilitiesDb?.find(f => f.lookupCode === code)?.sku
  }

  public getSKUIncludedInServiceType(sku: string, addons: boolean = false) {
    const serviceType = this.allServiceTypesDb?.find(s => s.sku === sku)
    if (!serviceType) return []

    const serviceTypeIncludedSkus = _.uniq([
      ...(addons ? serviceType.addons.map(a => a.facility ? a.facility.sku : a.product ? a.product.sku : null) : []),
      ...serviceType.facilities.map(f => f.facility.sku),
      ...serviceType.products.filter(p => p.product).map(p => p.product!.sku),
      ...serviceType.products.filter(p => p.bundle)
        .map(p => this.allBundlesDb?.find(b => b.id === p.bundle!.id))
        .filter(b => b)
        .reduce<string[]>((agg, b) => [...agg, ...b!.bundleItems.map(bi => this.allProductsDb?.find(p => p.id === bi.productId)?.sku)].filter(s => s).map(s => s!), [])
    ].filter(s => s).map(s => s!))
    return serviceTypeIncludedSkus
  }
  public getSKUBundleItems(sku: string) {
    const bundle = this.allBundlesDb?.find(b => b.sku === sku);
    if (!bundle) return  []

    return bundle.bundleItems.map(bi => ({ includedCount: bi.includedCount, sku: this.allProductsDb?.find(p => p.id === bi.productId)?.sku }))
      .filter(s => s.sku).map(s => ({ ...s, sku: s.sku! }))
  }

  public getSKURequiredComponents(sku: string) {
    const bundle = this.allBundlesDb?.find(b => b.sku === sku);
    if (bundle) return bundle.components;

    const product = this.allProductsDb?.find(p => p.sku === sku);
    if (product) return product.components;

    const facility = this.allFacilitiesDb?.find(f => f.sku === sku);
    if (facility) return facility.components;

    return null;
  }

  public getTaxRateForType(taxTypeId: number) {
    const taxRate = this.taxRateSelector?.getTaxRate({
      country: this.hotel!.businessCountry,
      date: new Date(),
      taxTypeId: taxTypeId,
    });
    return taxRate || null;
  }
  public getSKULineItem(sku: string, params: { date: Date, itemCount: number, guestCount?: number | null}, price?: IPrice | null, calcOptions?: ICalcOptions | null) {
    const bundle = this.allBundlesDb?.find(b => b.sku === sku);
    const product = this.allProductsDb?.find(p => p.sku === sku);
    const facility = this.allFacilitiesDb?.find(f => f.sku === sku);

    const priceList = this.getPriceList({ date: params.date });

    if (bundle) {
      return this.getBundleLineItem(
        0,
        {
          priceListId: priceList.id,
          bundleId: bundle.id,
          date: params.date,
          guestCount: params.guestCount || 0,
          itemCount: params.itemCount,
        },
        price, calcOptions);
    } else if (product) {
      return this.getProductLineItem(
        0,
        {
          priceListId: priceList.id,
          productId: product.id,
          date: params.date,
          guestCount: params.guestCount || 0,
          itemCount: params.itemCount,
        },
        price, calcOptions);
    } else if (facility) {
      return this.getFacilityLineItem(
        0,
        {
          priceListId: priceList.id,
          facilityId: facility.id,
          date: params.date,
          guestCount: params.guestCount || 0,
          itemCount: params.itemCount,
        }, price, calcOptions);
    } else {
      throw new QuickPriceError(`No product found for SKU ${sku}`, {
        extensions: { code: 'BAD_PRODUCT', sku },
      });
    }
  }

  public getBundleLineItem(day: number, selInput: PriceSelectorInput, price?: IPrice | null, calcOptions?: ICalcOptions | null) {
    const bundle = this.allBundlesDb?.find(b => b.id === selInput.bundleId);
    if (!bundle) return null;

    if (!price && calcOptions && calcOptions.priceSelector) {
      price = calcOptions.priceSelector(selInput)
    }
    if (!price && this.priceSelector) {
      price = this.priceSelector.findPrice(selInput);
    }
    if (!price && !calcOptions?.allowNoPrices)
      throw new QuickPriceError(`No price found for bundle ${bundle.name}`, {
        extensions: { code: 'BAD_PRICELIST', bundle: bundle.name },
      });

    const priceList = this.getPriceList({
      date: selInput.date,
      priceListId: selInput.priceListId,
    });

    if (price && price.bundlePriceFromProduct) {
      const productLineItems = bundle.bundleItems
        .map(bi => {
          try {
            return this.getProductLineItem(day, {
              ...selInput,
              itemCount: bi.includedCount,
              bundleId: undefined,
              productId: bi.productId,
              selectorId: price?.selector?.id,
            }, null, calcOptions);
          } catch (err: any) {
            console.warn(err.message);
            return null;
          }
        })
        .filter(pli => pli) as CalcLineItem<FacilityType, ProductType, ProductBundleType>[];
      const lineItem: CalcLineItem<FacilityType, ProductType, ProductBundleType> = {
        day: day,
        sku: bundle.sku,
        name: bundle.name,
        bundle,
        selector: price.selector,
        count: selInput.itemCount,
        isIncluded: false,
        priceItem: productLineItems.find(pli => pli.priceItem === NO_PRICE) ? NO_PRICE : productLineItems.reduce((s, pli) => s + pli.priceItem * pli.count, 0),
        priceGross: 0,
        priceNet: productLineItems.find(pli => pli.priceNet === NO_PRICE) ? NO_PRICE : productLineItems.reduce((s, pli) => s + pli.priceNet, 0) * selInput.itemCount,
        components: [],
        taxes: [],
      };
      const productLineItemTaxes = productLineItems.reduce(
        (s, pli) => [
          ...s,
          ...pli.taxes.map(t => ({
            ...t,
            price: t.price === NO_PRICE ? NO_PRICE : t.price * selInput.itemCount,
          })),
        ],
        [] as CalcTax[],
      );
      lineItem.priceGross = lineItem.priceNet === NO_PRICE || productLineItemTaxes.find(t => t.price === NO_PRICE) ? NO_PRICE : lineItem.priceNet + productLineItemTaxes.reduce((agg, t) => agg + t.price, 0)
      lineItem.taxes = this.mergeTaxes([], productLineItemTaxes);

      const productLineItemComponents = productLineItems.reduce(
        (s, pli) => [
          ...s,
          ...pli.components.map(plic => ({
            type: plic.type,
            price: plic.price === NO_PRICE ? NO_PRICE : plic.price * pli.count,
          })),
        ],
        [] as CalcPriceComponent[],
      );
      lineItem.components = this.mergeComponents([], productLineItemComponents);

      return lineItem;
    } else {
      const lineItem: CalcLineItem<FacilityType, ProductType, ProductBundleType> = {
        day: day,
        sku: bundle.sku,
        name: bundle.name,
        bundle,
        selector: price?.selector,
        count: selInput.itemCount,
        isIncluded: false,
        priceItem: price ? price.price || 0 : NO_PRICE,
        priceGross: 0,
        priceNet: 0,
        components: [],
        taxes: [],
      };

      if (price && bundle.components.length === 1) {
        price.components = [
          {
            price: price.price || 0,
            taxTypeId: bundle.components[0].taxTypeId,
          },
        ];
      }

      for (const bc of bundle.components) {
        const taxType = this.taxRateSelector?.getTaxType(bc.taxTypeId);
        const taxRate = this.taxRateSelector?.getTaxRate({
          country: this.hotel!.businessCountry,
          date: selInput.date,
          taxTypeId: bc.taxTypeId,
        });
        if (!taxRate || !taxType) {
          throw new QuickPriceError(`No tax rate found for bundle ${bundle.name}/${taxType?.name}`, {
            extensions: {
              code: 'BAD_PRICELIST',
              bundle: bundle.name,
              taxType: taxType?.name,
            },
          });
        }

        if (price) {
          const taxComponent = price.components?.find(c => c.taxTypeId === bc.taxTypeId);
          const taxComponentBasePrice = taxComponent ? taxComponent.price : 0;
          lineItem.components.push({
            type: taxType,
            price: taxComponentBasePrice,
          });

          if (taxComponentBasePrice !== NO_PRICE) {
            if (priceList!.isPricesNet) {
              lineItem.taxes.push({
                type: taxType,
                rate: taxRate,
                price: selInput.itemCount * roundPrice(taxComponentBasePrice * taxRate.rate),
              });
            } else {
              lineItem.taxes.push({
                type: taxType,
                rate: taxRate,
                price: selInput.itemCount * roundPrice(taxComponentBasePrice - taxComponentBasePrice / (1 + taxRate.rate)),
              });
            }
          } else {
            lineItem.taxes.push({
              type: taxType,
              rate: taxRate,
              price: NO_PRICE
            });
          }
        } else {
          lineItem.components.push({
            type: taxType,
            price: NO_PRICE,
          });
          lineItem.taxes.push({
            type: taxType,
            rate: taxRate,
            price: NO_PRICE,
          });
        }
      }
      lineItem.taxes = this.mergeTaxes([], lineItem.taxes);
      lineItem.components = this.mergeComponents([], lineItem.components);

      if (price && price.price !== NO_PRICE && !lineItem.taxes.find(t => t.price === NO_PRICE)) {
        if (priceList!.isPricesNet) {
          lineItem.priceNet = selInput.itemCount * (price.price || 0);
          lineItem.priceGross = lineItem.priceNet + lineItem.taxes.reduce((sum, t) => sum + t.price, 0);
        } else {
          lineItem.priceGross = selInput.itemCount * (price.price || 0);
          lineItem.priceNet = lineItem.priceGross - lineItem.taxes.reduce((sum, t) => sum + t.price, 0);
        }
      } else {
        lineItem.priceNet = NO_PRICE;
        lineItem.priceGross = NO_PRICE;
      }
      return lineItem;
    }
  }

  public getProductLineItem(day: number, selInput: PriceSelectorInput, price?: IPrice | null, calcOptions?: ICalcOptions | null) {
    const product = this.allProductsDb?.find(p => p.id === selInput.productId);
    if (!product) return null;
    
    if (!price && calcOptions && calcOptions.priceSelector) {
      price = calcOptions.priceSelector(selInput)
    }
    if (!price && this.priceSelector) {
      price = this.priceSelector.findPrice(selInput);
    }
    if (!price && !calcOptions?.allowNoPrices)
      throw new QuickPriceError(`No price found for product ${product.name}`, {
        extensions: { code: 'BAD_PRICELIST', product: product.name },
      });

    const priceList = this.getPriceList({
      date: selInput.date,
      priceListId: selInput.priceListId,
    });

    const lineItem: CalcLineItem<FacilityType, ProductType, ProductBundleType> = {
      day: day,
      sku: product.sku,
      name: product.name,
      product,
      selector: price?.selector,
      count: selInput.itemCount,
      isIncluded: false,
      priceItem: price ? price.price || 0 : NO_PRICE,
      priceGross: 0,
      priceNet: 0,
      components: [],
      taxes: [],
    };

    const multi = product.isDeduction ? -1 : 1;

    if (price && product.components.length === 1) {
      price.components = [
        {
          price: price.price || 0,
          taxTypeId: product.components[0].taxTypeId
        }
      ];
    }

    for (const bc of product.components) {
      const taxType = this.taxRateSelector?.getTaxType(bc.taxTypeId);
      const taxRate = this.taxRateSelector?.getTaxRate({
        country: this.hotel!.businessCountry,
        date: selInput.date,
        taxTypeId: bc.taxTypeId,
      });
      if (!taxRate || !taxType) {
        throw new QuickPriceError(`No tax rate found for product ${product.name}/${taxType?.name}`, {
          extensions: {
            code: 'BAD_PRICELIST',
            product: product.name,
            taxType: taxType?.name,
          },
        });
      }

      if (price) {
        const taxComponent = price.components?.find(c => c.taxTypeId === bc.taxTypeId);
        const taxComponentBasePrice = taxComponent ? taxComponent.price : 0;
        lineItem.components.push({
          type: taxType,
          price: taxComponentBasePrice,
        });

        if (taxComponentBasePrice !== NO_PRICE) {
          if (priceList!.isPricesNet) {
            lineItem.taxes.push({
              type: taxType,
              rate: taxRate,
              price: multi * selInput.itemCount * roundPrice(taxComponentBasePrice * taxRate.rate),
            });
          } else {
            lineItem.taxes.push({
              type: taxType,
              rate: taxRate,
              price: selInput.itemCount * roundPrice(taxComponentBasePrice - taxComponentBasePrice / (1 + taxRate.rate)),
            });
          }
        } else {
          lineItem.taxes.push({
            type: taxType,
            rate: taxRate,
            price: NO_PRICE
          });
        }
      } else {
        lineItem.components.push({
          type: taxType,
          price: NO_PRICE,
        });
        lineItem.taxes.push({
          type: taxType,
          rate: taxRate,
          price: NO_PRICE,
        });
      }
    }
    lineItem.taxes = this.mergeTaxes([], lineItem.taxes);
    lineItem.components = this.mergeComponents([], lineItem.components);

    if (price && price.price !== NO_PRICE && !lineItem.taxes.find(t => t.price === NO_PRICE)) {
      if (priceList!.isPricesNet) {
        lineItem.priceNet = multi * selInput.itemCount * (price.price || 0);
        lineItem.priceGross = lineItem.priceNet + lineItem.taxes.reduce((sum, t) => sum + t.price, 0) * multi;
      } else {
        lineItem.priceGross = multi * selInput.itemCount * (price.price || 0);
        lineItem.priceNet = lineItem.priceGross - lineItem.taxes.reduce((sum, t) => sum + t.price, 0) * multi;
      }
    } else {
      lineItem.priceNet = NO_PRICE;
      lineItem.priceGross = NO_PRICE;
    }
    return lineItem;
  }

  public getFacilityLineItem(day: number, selInput: PriceSelectorInput, price?: IPrice | null, calcOptions?: ICalcOptions | null) {
    const facility = this.allFacilitiesDb?.find(f => f.id === selInput.facilityId);
    if (!facility) return null;

    if (!price && calcOptions && calcOptions.priceSelector) {
      price = calcOptions.priceSelector(selInput)
    }
    if (!price && this.priceSelector) {
      price = this.priceSelector.findPrice(selInput);
    }
    if (!price && !calcOptions?.allowNoPrices)
      throw new QuickPriceError(`No price found for facility ${facility.name}`, {
        extensions: { code: 'BAD_PRICELIST', facility: facility.name },
      });

    const priceList = this.getPriceList({
      date: selInput.date,
      priceListId: selInput.priceListId,
    });

    const taxRatesToApply: ITaxRate[] = [];

    for (const c of facility.components) {
      const taxRate = this.taxRateSelector?.getTaxRate({
        country: this.hotel!.businessCountry,
        date: selInput.date,
        taxTypeId: c.taxTypeId,
      });
      if (!taxRate) {
        const taxType = this.taxRateSelector?.getTaxType(c.taxTypeId)!;
        throw new QuickPriceError(`No tax rate found for facility ${facility.name}/${taxType.name}`, {
          extensions: {
            code: 'BAD_PRICELIST',
            facility: facility.name,
            taxType: taxType.name,
          },
        });
      }
      taxRatesToApply.push(taxRate);
    }

    const lineItem: CalcLineItem<FacilityType, ProductType, ProductBundleType> = {
      day: day,
      sku: facility.sku,
      name: facility.name,
      facility,
      selector: price?.selector,
      count: selInput.itemCount,
      isIncluded: false,
      priceItem: price ? price.price || 0 : NO_PRICE,
      priceGross: 0,
      priceNet: 0,
      components: [],
      taxes: [],
    };

    if (price && facility.components.length === 1) {
      price.components = [
        {
          price: price.price || 0,
          taxTypeId: facility.components[0].taxTypeId,
        },
      ];
    }

    for (const bc of facility.components) {
      const taxType = this.taxRateSelector?.getTaxType(bc.taxTypeId);
      const taxRate = this.taxRateSelector?.getTaxRate({
        country: this.hotel!.businessCountry,
        date: selInput.date,
        taxTypeId: bc.taxTypeId,
      });
      if (!taxRate || !taxType) {
        throw new QuickPriceError(`No tax rate found for facility ${facility.name}/${taxType?.name}`, {
          extensions: {
            code: 'BAD_PRICELIST',
            facility: facility.name,
            taxType: taxType?.name,
          },
        });
      }
      if (price) {
        const taxComponent = price.components?.find(c => c.taxTypeId === bc.taxTypeId);
        const taxComponentBasePrice = taxComponent ? taxComponent.price : 0;

        lineItem.components.push({
          type: taxType,
          price: taxComponentBasePrice,
        });

        if (taxComponentBasePrice !== NO_PRICE) {
          if (priceList!.isPricesNet) {
            lineItem.taxes.push({
              type: taxType,
              rate: taxRate,
              price: selInput.itemCount * roundPrice(taxComponentBasePrice * taxRate.rate),
            });
          } else {
            lineItem.taxes.push({
              type: taxType,
              rate: taxRate,
              price: selInput.itemCount * roundPrice(taxComponentBasePrice - taxComponentBasePrice / (1 + taxRate.rate)),
            });
          }
        } else {
          lineItem.taxes.push({
            type: taxType,
            rate: taxRate,
            price: NO_PRICE
          });
        }
      } else {
        lineItem.components.push({
          type: taxType,
          price: NO_PRICE,
        });
        lineItem.taxes.push({
          type: taxType,
          rate: taxRate,
          price: NO_PRICE,
        });
      }
    }
    lineItem.taxes = this.mergeTaxes([], lineItem.taxes);
    lineItem.components = this.mergeComponents([], lineItem.components);

    if (price && price.price !== NO_PRICE && !lineItem.taxes.find(t => t.price === NO_PRICE)) {
      if (priceList!.isPricesNet) {
        lineItem.priceNet = selInput.itemCount * (price.price || 0);
        lineItem.priceGross = lineItem.priceNet + lineItem.taxes.reduce((sum, t) => sum + t.price, 0);
      } else {
        lineItem.priceGross = selInput.itemCount * (price.price || 0);
        lineItem.priceNet = lineItem.priceGross - lineItem.taxes.reduce((sum, t) => sum + t.price, 0);
      }
    } else {
      lineItem.priceNet = NO_PRICE
      lineItem.priceGross = NO_PRICE
    }
    return lineItem;
  }
}
