import React, { useState } from 'react';
import { Button, IconButton } from '@mui/material';
import ConfirmationDialog from './ConfirmationDialog';

interface ConfirmationButtonProps {
  confirmationTitle: string | null;
  confirmationQuestion?: React.ReactNode | React.ReactNode[];
  doubleConfirmationQuestion?: React.ReactNode | React.ReactNode[];
  onConfirm: () => void;
  icon?: boolean | null;
  disabled?: boolean;
  children: React.ReactNode | React.ReactNode[];
  [x: string]: any;
}

export default function ConfirmationButton(props: ConfirmationButtonProps) {
  const { confirmationTitle, onConfirm, confirmationQuestion, doubleConfirmationQuestion, icon, children, ...rest } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);

  return (
    <>
      {icon && (
        <IconButton onClick={() => (confirmationQuestion ? setConfirmOpen(true) : onConfirm())} {...rest}>
          {children}
        </IconButton>
      )}
      {!icon && (
        <Button onClick={() => (confirmationQuestion ? setConfirmOpen(true) : onConfirm())} {...rest}>
          {children}
        </Button>
      )}
      {confirmationQuestion && (
        <ConfirmationDialog title={confirmationTitle || ''} open={confirmOpen} setOpen={setConfirmOpen} onConfirm={onConfirm} doubleConfirmationQuestion={doubleConfirmationQuestion}>
          {confirmationQuestion}
        </ConfirmationDialog>
      )}
    </>
  );
}
